import Vue from "vue";

Vue.filter("insurancePrice", (value) => {
  if (!value && value !== 0) {
    return "";
  }
  const parts = value.toString().split(".");
  const integerPart = parts[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const decimalPart = parts.length > 1 ? `.${parts[1].replace(/\D/g, "")}` : "";

  return integerPart + decimalPart;
});
