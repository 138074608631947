import ls from "@/plugins/secureLs";
import io from "socket.io-client";
import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const carriers = Repository.service("carriers");
const docGenerate = Repository.service("docsGenerate");

export default {
  namespaced: true,
  state: {
    allCarriers: [],
    allTrucks: null,
    carriersImages: null,
    carrierById: null,
    carriersSocket: null,
    responseCarrierSocket: [],
    chosenLoadCargoValue: null,
  },
  getters: {
    getCarriers(state) {
      return state.allCarriers;
    },
    getCarriersImages(state) {
      return state.carriersImages;
    },
    getCarrierById(state) {
      return state.carrierById;
    },
    getCarriersSocket(state) {
      return state.responseCarrierSocket;
    },
    getChosenLoadCargoValue(state) {
      return state.chosenLoadCargoValue;
    },
  },
  mutations: {
    setCarriers(state, payload) {
      state.allCarriers = [...state.allCarriers, ...payload];
    },
    resetCarriersList(state) {
      state.allCarriers = [];
    },
    clearCarriers(state) {
      state.allCarriers = [];
    },
    setTrucks(state, payload) {
      state.allTrucks = payload;
    },
    setCarriersImages(state, payload) {
      state.carriersImages = payload;
    },
    setCarrierById(state, payload) {
      state.carrierById = payload;
    },
    setChosenLoadCargoValue(state, payload) {
      state.chosenLoadCargoValue = payload;
    },
    setSocketResponse(state, payload) {
      state.responseCarrierSocket = payload;
    },
    setCarriersSocket(state, payload) {
      state.carriersSocket = io(payload.route, {
        path: "/sio/socket.io/",
        transports: ["websocket"],
        forceNew: true,
        query: {
          Authorization: `Bearer ${ls.get("portal-front-token")}`,
          room_id: payload.loadId,
        },
      });
    },
    disconnectSocket(state) {
      if (state.carriersSocket) {
        state.carriersSocket.disconnect();
      }
    },
  },
  actions: {
    async getAllOptionsCarriers({ commit, rootGetters }, loadId) {
      try {
        const response = await carriers.getAllOptionsCarriers(loadId);
        commit("setCarriers", response.carriers);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getCarriersImages({ commit, rootGetters, getters }) {
      try {
        let response = getters.getCarriersImages;
        if (!response) {
          response = await docGenerate.getDocsCarriers();
        }
        commit("setCarriersImages", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getCarrierById({ commit, rootGetters }, carrierId) {
      try {
        const response = await carriers.getCarrierById(carrierId);
        commit("setCarrierById", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    joinToCarriersSocket({ commit, dispatch }, id) {
      const loadId = id;
      const route = `${process.env.VUE_APP_BACK_ROUTE}/notifications`;
      const payload = {
        route,
        loadId,
      };
      commit("setCarriersSocket", payload);
      dispatch("carriersSocketListener");
    },
    carriersSocketListener({ state, commit }) {
      state.carriersSocket.on("new-quote", (data) => {
        commit("setSocketResponse", { notification: "new-quote", data });
        commit("setCarriers", JSON.parse(data.carriers));
      });
    },
  },
};
