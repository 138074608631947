import ls from "@/plugins/secureLs";
import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";
import DecodeToken from "../../utils/DecodeToken";

const signIn = Repository.service("signIn");

export default {
  namespaced: true,
  state: {
    tokenInfo: null,
    loadingLogin: false,
  },
  getters: {
    getTokenInfo(state) {
      return state.tokenInfo;
    },
    getLoadingLogin(state) {
      return state.loadingLogin;
    },
  },
  mutations: {
    setLoadingLogin(state, payload) {
      state.loadingLogin = payload;
    },
    setTokenInfo(state, payload) {
      state.tokenInfo = payload;
    },
    modifyTokenInfo(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.tokenInfo[key] = payload[key];
      });
    },
  },
  actions: {
    async signIn({ commit, dispatch }, body) {
      try {
        const loginFormData = new FormData();
        loginFormData.append("username", body.email);
        loginFormData.append("password", body.password);
        const response = await signIn.signIn(loginFormData);
        ls.set("portal-front-token", response.access_token);
        const decodedToken = await dispatch("decodeToken", response.access_token);
        const tokenResponse = { data: response, decodedToken };
        await dispatch("getAccountSettings", tokenResponse);
        return tokenResponse;
      } catch (error) {
        commit("setLoadingLogin", false);
        return errorMessage("Invalid email or password");
      }
    },
    async signInMagaya({ dispatch, rootGetters }, params) {
      try {
        const response = await signIn.signInMagaya(params);
        // eslint-disable-next-line camelcase
        const { default_percent_markup, default_min_markup } = response.user_account_settings;
        const data = {
          markup_min: default_min_markup,
          markup_percent: default_percent_markup,
        };
        localStorage.setItem("magaya-markups", JSON.stringify(data));
        ls.set("portal-front-token", response.access_token);
        const decodedToken = await dispatch("decodeToken", response.access_token);
        const tokenResponse = { data: response, decodedToken };
        await dispatch("getAccountSettings", tokenResponse);
        return decodedToken;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async loginWithOtherAccount({ rootGetters }, id) {
      try {
        const response = await signIn.loginWithAccountId(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAccountSettings({ dispatch, rootGetters }, params) {
      try {
        const response = await dispatch("account/getAccountSettings", params, { root: true });
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async refreshToken({ rootGetters }, params) {
      try {
        return signIn.refreshToken(params);
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async validateMagayaCredentials({ rootGetters }, params) {
      try {
        return signIn.validateMagayaCredentials(params);
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    decodeToken({ commit }, token) {
      const decodedToken = DecodeToken(token);
      commit("setTokenInfo", JSON.parse(decodedToken.sub));
      return decodedToken;
    },
  },
};
