import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const requestDemo = Repository.service("requestDemo");

export default {
  namespaced: true,
  actions: {
    async sendRequestDemo({ rootGetters }, payload) {
      try {
        const response = await requestDemo.sendRequestDemo(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
