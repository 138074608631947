import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const cancellation = Repository.service("cancellation");

export default {
  namespaced: true,
  state: {
    cancellationStatus: false,
    currentCancellationReason: null
  },
  getters: {
    getCancellationStatus(state) {
      return state.cancellationStatus;
    },
    getCurrentCancellationReason(state) {
      return state.currentCancellationReason;
    }
  },
  mutations: {
    setCancellationStatus(state, payload) {
      state.cancellationStatus = payload;
    },
    setCurrentCancellationReason(state, payload) {
      state.currentCancellationReason = payload;
    }
  },
  actions: {
    async getCancellationStatus({ rootGetters }, loadId) {
      try {
        const response = await cancellation.getCancellationStatus(loadId);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async cancelLoad({ commit, rootGetters }, payload) {
      try {
        const response = await cancellation.cancelLoad(payload);
        commit("setCurrentCancellationReason", payload.body.reason);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
