import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const truckTypes = Repository.service("truckTypes");

export default {
  namespaced: true,
  state: {
    allTruckTypes: null,
  },
  getters: {
    getTruckTypes(state) {
      return state.allTruckTypes;
    },
  },
  mutations: {
    setAllTruckTypes(state, payload) {
      state.allTruckTypes = payload;
    },
  },
  actions: {
    async getAllTruckTypes({ commit, rootGetters, getters }, params) {
      try {
        let response = getters.getTruckTypes;
        if (!response) {
          response = await truckTypes.getAllTruckTypes(params);
          commit("setAllTruckTypes", response);
        }
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createTruckType({ rootGetters }, params) {
      try {
        const response = await truckTypes.createTruckType(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateTruckType({ rootGetters }, params) {
      try {
        const response = await truckTypes.updateTruckType(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateTruckTypeImage({ rootGetters }, params) {
      try {
        const response = await truckTypes.updateTruckTypeImage(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteTruckType({ rootGetters }, id) {
      try {
        const response = await truckTypes.deleteTruckType(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
