/* eslint-disable global-require */
const sidebarOptions = [
  // {
  //   pathName: "Home",
  //   pathRoute: "home",
  //   text: "sidebar.home",
  //   role: ["all"],
  //   id: "home",
  //   icon: require("@/assets/scss/icons/house-solid.svg"),
  // },
  {
    pathName: "Dashboard",
    pathRoute: "home",
    text: "sidebar.home",
    role: ["all"],
    id: "dashboard",
    icon: require("@/assets/scss/icons/house-solid.svg"),
  },
  {
    pathName: "QuoteBook",
    pathRoute: "quote-book",
    text: "sidebar.quoteBook",
    role: ["all"],
    id: "quoteBook",
    icon: require("@/assets/scss/icons/truck-fast-solid.svg"),
  },
  {
    pathName: "MyLoads",
    pathRoute: "my-loads",
    text: "sidebar.myLoads",
    role: ["all"],
    id: "myLoads",
    icon: require("@/assets/scss/icons/clipboard-list-solid.svg"),
  },
  {
    pathName: "paymentHub",
    pathRoute: "payment-hub",
    text: "sidebar.paymentHub",
    role: ["all"],
    id: "paymentHub",
    icon: require("@/assets/scss/icons/credit-card.svg"),
  },
  {
    pathName: "Insurance",
    pathRoute: "insurance",
    text: "sidebar.insurance",
    role: ["all"],
    id: "insurance",
    icon: require("@/assets/scss/icons/insurance-icon-sidebar.svg"),
  },
  {
    pathName: "Accounts",
    pathRoute: "account-management",
    text: "sidebar.accountManagement",
    role: ["glt admin"],
    id: "accountManagement",
    icon: require("@/assets/scss/icons/users-gear-solid.svg"),
  },
  {
    pathName: "Settings",
    pathRoute: "settings",
    text: "sidebar.settings",
    role: ["glt admin", "account admin", 'customer agent'],
    id: "settings",
    icon: require("@/assets/scss/icons/gear-solid.svg"),
  },
  {
    pathName: "How-to Videos",
    pathRoute: "external",
    external: true,
    href: "https://goglt.com/tutorial-videos/?accessToken=1aBc9Z2Y",
    text: "sidebar.howToVideos",
    role: ["all"],
    id: "howToVideos",
    icon: require("@/assets/scss/icons/desktop-solid.svg"),
  },
];

export default sidebarOptions;
