import api from "../clients/axios";

const resource = "clone";

export default {
  async cloneLoad(data) {
    const response = await api.get(`${resource}/${data.id}`);
    return response.data;
  },
};
