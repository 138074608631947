import api from "../clients/axios";

const resource = "carriers-alert";

export default {
  async getAllCarriersAlert() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async createAllCarriersAlert(payload) {
    const response = await api.post(`${resource}`, payload);
    return response.data;
  },
  async getCarriersAlertById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async deleteCarriersAlert(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
  async updateCarriersAlert(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, payload.body);
    return response.data;
  },
};
