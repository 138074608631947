// import Router from "@/router";
import { errorMessage } from "../../utils/ErrorMessages";
import Repository from '../../services/repositories/RepositoryFactory';

const user = Repository.service("user");

export default {
  namespaced: true,
  state: {
    users: null,
    myInfo: null,
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getMyInfo(state) {
      return state.myInfo;
    }
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    setMyInfo(state, payload) {
      state.myInfo = payload;
    }
  },
  actions: {
    async getMyInfo({ rootGetters }) {
      try {
        const response = await user.getMyInfo();
        return response;
      } catch (error) {
        // if (error.response.status === 401) {
        //   Router.push({ name: "Login" });
        // }
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAllMyInfo({ commit, rootGetters }) {
      try {
        const response = await user.getAllMyInfo();
        commit("setMyInfo", response);
        return response;
      } catch (error) {
        // if (error.response.status === 401) {
        //   Router.push({ name: "Login" });
        // }
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createAccount({ rootGetters }, body) {
      try {
        const response = await user.createAccount(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createUser({ rootGetters }, body) {
      try {
        const response = await user.createUser(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createMagayaUser({ rootGetters }, body) {
      try {
        const response = await user.createMagayaUser(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async forgotPassword({ rootGetters }, body) {
      try {
        const response = await user.forgotPassword(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async recoveryPassword({ rootGetters }, body) {
      try {
        const response = await user.recoveryPassword(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getUsers({ commit, rootGetters, getters }) {
      try {
        let response = getters.getUsers;
        if (!response) {
          response = await user.getUsers();
        }
        commit("setUsers", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async validateIfTheEmailExist({ rootGetters }, params) {
      try {
        const response = await user.validateIfTheEmailExist(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateUserInfo({ rootGetters }, body) {
      try {
        const response = await user.updateUserInfo(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateUserTour({ rootGetters }, body) {
      try {
        const response = await user.updateUserTour(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteUser({ rootGetters }, id) {
      try {
        const response = await user.deleteUser(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
