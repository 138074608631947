import { denyRouteAccess } from "../routerHelpers";

const myLoadsRoutes = [
  {
    path: "my-loads",
    name: "MyLoads",
    meta: {
      title: "My Loads | Portal",
    },
    component: () => import("../../views/MyLoads/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
    },
  },
  {
    path: "my-loads/:loadId",
    name: "LoadsDetails",
    component: () => import("../../views/LoadDetails/Index.vue"),
    beforeEnter: (to, from, next) => {
      document.title = `Load Details ${to.params.loadId} | Portal`;
      denyRouteAccess(to, next);
    },
  },
];

export default myLoadsRoutes;
