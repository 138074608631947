import api from "../clients/axios";

const resource = "account-children"; // Check the route in the backend docs

export default {
  async getAllChildrenAccounts(params) {
    const response = await api.get(`${resource}`, { params });
    return response.data;
  },
  async createChildAccount(payload) {
    const response = await api.post(`${resource}`, payload);
    return { data: response.data, operation: "create" };
  },
  async updateChildAccount(payload) {
    await api.patch(`${resource}/${payload.id}`, payload.body);
    return { data: payload.body, operation: "update", id: payload.id };
  },
  async deleteChildAccount(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
};
