/* eslint no-underscore-dangle: 0 */
import ls from "@/plugins/secureLs";
import router from "@/router";
// import store from "@/store";
import swal from "sweetalert";
import api from "./axios";
import setLogoutPath from "../../utils/SetLogoutPath";

export default function setup() {
  if (router.currentRoute.name !== "Login") {
    // Request interceptor for API calls
    api.interceptors.request.use(
      (config) => {
        const axiosConfig = config;
        const token = ls.get("portal-front-token");
        if (token) {
          axiosConfig.headers.Authorization = `Bearer ${token}`;
        }
        return axiosConfig;
      },
      (err) => Promise.reject(err),
    );
    // Response interceptor for API calls
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          await swal({
            title: "The token has expired",
            text: "Please, login again",
            icon: "warning",
            dangerMode: true,
          }).then(() => {
            setLogoutPath();
          });
        }
        return Promise.reject(error);
      },
    );
  }
}
