import api from "../clients/axios";

const resource = "docs";

export default {
  async getDocsByLoadId(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async generateDocsByLoadId(payload) {
    const response = await api.get(`${resource}/generate/${payload.id}`, {
      params: { document: payload.document }
    });
    return response.data;
  },
  async getDocsCarriers() {
    const response = await api.get(`${resource}/carriers`);
    return response.data;
  },
  async getDocuments(id) {
    const response = await api.get(`${resource}/${id}/${resource}`);
    return response.data;
  },
  async getInvoiceDocument(id) {
    const response = await api.get(`${resource}/${id}/invoice`);
    return response.data;
  },
  async getAttachmentsDocs() {
    const response = await api.get(`${resource}/account/attachments`);
    return response.data;
  }
};
