import Repository from "../../../../services/repositories/RepositoryFactory";
import { errorMessage } from "../../../../utils/ErrorMessages";

const lineItems = Repository.service("load");

export default {
  namespaced: true,
  state: {
    lineItemsPickList: null,
    selectedLineItems: [],
    lineItemsLimitsValidation: false,
    temperatureValue: 0,
    temperatureValueToSend: 0,
    temperatureUnitMeasurement: "F",
    triggerValidationTemperature: 0,
    validationTemperature: false,
    lineItemsLimitsMessages: [],
    lineItemsResumeInfo: null
  },
  getters: {
    getLineItemsPicklists(state) {
      return state.lineItemsPickList;
    },
    getSelectedLineItems(state) {
      return state.selectedLineItems;
    },
    getLineItemsLimitValidation(state) {
      return state.lineItemsLimitsValidation;
    },
    getLineItemsTemperature(state) {
      return state.temperatureValue;
    },
    getLineItemsTemperatureToSend(state) {
      return state.temperatureValueToSend;
    },
    getLineItemsTemperatureUnits(state) {
      return state.temperatureUnitMeasurement;
    },
    getTriggerValidations(state) {
      return state.triggerValidationTemperature;
    },
    getValidationTemperature(state) {
      return state.validationTemperature;
    },
    getLineItemsLimitMessage(state) {
      return state.lineItemsLimitsMessages;
    },
    getLineItemsResumeInfo(state) {
      return state.lineItemsResumeInfo;
    }
  },
  mutations: {
    setLineItems(state, payload) {
      state.lineItemsPickList = payload;
    },
    setSelectedLineItems(state, payload) {
      state.selectedLineItems = payload;
    },
    deleteLineItem(state, index) {
      state.selectedLineItems.splice(index, 1);
    },
    resetSelectedLineItems(state) {
      state.selectedLineItems = [];
    },
    setLineItemsLimitValidation(state, payload) {
      state.lineItemsLimitsValidation = payload;
    },
    setLineItemsTemperature(state, payload) {
      state.temperatureValue = payload;
    },
    setTemperatureToSendValue(state, payload) {
      state.temperatureValueToSend = payload;
    },
    setTriggerValidations(state) {
      state.triggerValidationTemperature += 1;
    },
    setTemperatureUnitMeasurement(state, payload) {
      state.temperatureUnitMeasurement = payload;
    },
    setValidationTemperature(state, payload) {
      state.validationTemperature = payload;
    },
    setLineItemsLimitMessages(state, payload) {
      state.lineItemsLimitsMessages.push(payload);
    },
    resetLineItemsLimitMessage(state) {
      state.lineItemsLimitsMessages = [];
    },
    setLineItemsResumeInfo(state, payload) {
      state.lineItemsResumeInfo = payload;
    }
  },
  actions: {
    async getLineItemPickList({ commit, rootGetters, getters }) {
      try {
        let response = getters.getLineItemsPicklists;
        if (!response) {
          response = await lineItems.getLineItemPickList();
        }
        commit("setLineItems", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createLineItem({ rootGetters }, payload) {
      try {
        const response = await lineItems.createLineItem(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updatedLineItem({ rootGetters }, payload) {
      try {
        const response = await lineItems.updatedLineItem(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteLineItem({ rootGetters }, payload) {
      try {
        const response = await lineItems.deleteLineItem(payload.load_id, payload.id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    }
  },
};
