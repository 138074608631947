import api from "../clients/axios";

const resource = "standardizers";

export default {
  async getNmfcStandardizers(params) {
    const response = await api.get(`${resource}`, params);
    return response.data;
  },
  async calculateLineItemsInfo(payload) {
    const response = await api.post(`${resource}/line-items/info`, payload.body, {
      params: { ...payload.params },
    });
    return response.data;
  },
};
