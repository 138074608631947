/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */

import Vue from "vue";
import { Icon } from "leaflet";
import {
  LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component("l-map", LMap);
Vue.component("l-icon", LIcon);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);
Vue.component("l-tooltip", LTooltip);
