import swal from "sweetalert";

export function errorMessage(message) {
  swal({
    title: `${message}`,
    text: "Please, try again.",
    icon: "error",
    dangerMode: true,
  });
}

export function errorNotFoundMessage(event) {
  swal({
    title: "There is something wrong",
    text: `${event} not found!`,
    icon: "error",
    dangerMode: true,
  });
}

export function tokenExpired() {
  swal({
    title: "The token has expired",
    text: "Please, login again",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
  this.$router.push({
    name: "Login",
  });
}

export function confirmChange() {
  swal({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      swal("Deleted!", {
        icon: "success",
      });
    }
  });
}

export function generalMessage(event) {
  swal({
    title: 'Good Job!',
    text: `${event}`,
    icon: "success",
  });
}

export function warningMessage(message, subText = 'Please try again') {
  swal({
    title: `${message}`,
    text: `${subText}`,
    icon: "warning",
    dangerMode: false,
  });
}
