import api from "../clients/axios";

const resource = "salesforce-contacts"; // Check the route in the backend docs

export default {
  // async getSalesforceContacts(data) {
  //   const response = await api.get(`${resource}`, { params: data.params });
  //   return response.data;
  // },
  async createSalesforceContacts(body) {
    const response = await api.post(`${resource}`, body);
    return response.data;
  },
  async deleteSalesforceContact(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
  async getSalesforceContactsByAccount(data, abortController) {
    const response = await api.get(`${resource}/account/${data.params.account_id}`, {
      signal: abortController.signal,
    });
    return response.data;
  },
};
