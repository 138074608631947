import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const pcmiller = Repository.service("pcmiller");

export default {
  namespaced: true,
  state: {
    coordinates: null,
  },
  getters: {
    getCoordinates(state) {
      return state.coordinates;
    },
  },
  mutations: {
    setCoordinatesPcmiller(state, payload) {
      state.coordinates = payload;
    },
  },
  actions: {
    async getCoordinatesPcmiller({ commit, rootGetters }, params) {
      try {
        const response = await pcmiller.getCoordinatesPcmiller(params);
        commit("setCoordinatesPcmiller", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
