import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const carousel = Repository.service("carousel");

export default {
  namespaced: true,
  state: {
    carouselItems: [],
  },
  getters: {
    getCarouselItems(state) {
      return state.carouselItems;
    },
  },
  mutations: {
    setCarouselItems(state, payload) {
      state.carouselItems = payload;
    },
  },
  actions: {
    async getAllCarouselInfo({ commit, rootGetters, state }) {
      try {
        if (state.carouselItems.length > 0) {
          return state.carouselItems;
        }
        const response = await carousel.getAllCarouselInfo();
        commit("setCarouselItems", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async uploadVideo({ rootGetters }, payload) {
      try {
        const response = await carousel.uploadVideo(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async uploadImage({ rootGetters }, payload) {
      try {
        const response = await carousel.uploadImage(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateCarouselItems({ rootGetters }, payload) {
      try {
        const response = await carousel.updateCarouselItems(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateCarouselOrder({ rootGetters }, payload) {
      try {
        const response = await carousel.updateCarouselOrder(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteItemCarousel({ rootGetters }, id) {
      try {
        const response = await carousel.deleteItemCarousel(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
