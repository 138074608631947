import api from '../clients/axios';

const resource = 'pcmiller';

async function getCoordinatesPcmiller(params) {
  const response = await api.get(`${resource}`, params);
  return response.data;
}

export default {
  getCoordinatesPcmiller
};
