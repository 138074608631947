import qs from "qs";
import api from "../clients/axios";

const resource = "accounts";
const users = "users";

export default {
  async getAllAccounts(payload) {
    const response = await api.get(`${resource}/v2`, {
      params: { ...payload },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    return response.data;
  },
  async getAccountById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async updateAccount(data) {
    const response = await api.patch(`${resource}/${data.id}`, data.body);
    return response.data;
  },
  async getAllUsersByAccountId(id) {
    const response = await api.get(`${resource}/${id}/${users}`);
    return response.data;
  },
  async createUserByAccountId(payload) {
    const response = await api.post(`${resource}/${payload.id}/${users}`, payload.body);
    return response.data;
  },
  async deleteAccount(payload) {
    const response = await api.delete(`${resource}/${payload.id}`);
    return response.data;
  },
  // Account settings
  async getAccountSettings() {
    const response = await api.get(`${resource}/settings`);
    return response.data;
  },
  async getAccountModes() {
    const response = await api.get(`${resource}/modes`);
    return response.data;
  },
  async updateAccountSettings(payload) {
    const response = await api.patch(`${resource}/settings`, payload.settings, {
      params: payload.id,
    });
    return response.data;
  },
  async updateAccountLogo(payload) {
    const response = await api.patch(`${resource}/${payload.id}/settings/logo`, payload.formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return { logo: response.data.updated_account.settings.logo };
  },
  async updateDocumentsLogo(payload) {
    const response = await api.patch(
      `${resource}/${payload.id}/settings/documents-logo`,
      payload.formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return { documents_logo: response.data.updated_account.settings.documents_logo };
  },
  async getStatement() {
    const response = await api.get(`${resource}/get-statement`, {
      responseType: "blob",
      transformResponse: [
        (data, headers) => {
          const filename = headers.filename.split("/").pop();
          const blob = new File([data], filename);
          return blob;
        },
      ],
    });
    return response.data;
  },
  async uploadStatement(payload) {
    const response = await api.post(
      `${resource}/${payload.id}/upload-statement`,
      payload.formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  },
  async updateMarkups(payload) {
    const response = await api.patch(`${resource}/magaya/markups`, payload);
    return response.data;
  },
  async getAccountContacts(accountId) {
    const response = await api.get(`${resource}/${accountId}/contacts`);
    return response.data;
  },

  /* Tracking-Preference */
  async getTrackingPreferences() {
    const response = await api.get(`${resource}/tracking-preferences`);
    return response.data;
  },
  async updateTrackingPreferences(payload) {
    const response = await api.patch(`${resource}/tracking-preferences`, payload);
    return response.data;
  }
};
