import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const truckTypes = Repository.service("time");

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getTimeZone({ rootGetters }, params) {
      try {
        const response = await truckTypes.getTimeZone(params);
        // commit("setAllTruckTypes", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    }
  }
};
