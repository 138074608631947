import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const magaya = Repository.service("magaya");

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getMagayaCompanyExist({ rootGetters }, id) {
      try {
        const response = await magaya.getIfMagayaCompanyExist(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async validateMagayaCredentials({ rootGetters }, payload) {
      try {
        const response = await magaya.validateMagayaCredentials(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateMagayaCredentials({ rootGetters }, payload) {
      try {
        const response = await magaya.updateMagayaCredentials(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    }
  },
};
