import api from "../clients/axios";

const resource = "load-accessorials"; // Check the route in the backend docs

export default {
  async getAllLoadAccessorials() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async createLoadAccessorials(body) {
    const response = await api.post(`${resource}`, body);
    return response.data;
  },
  async getLoadAccessorialsById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async deleteLoadAccessorials(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
  async updateLoadAccessorials(data) {
    const response = await api.patch(`${resource}/${data.id}`, data.body);
    return response.data;
  },
};
