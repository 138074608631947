import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const AccountComodityService = Repository.service("accountCommodity");

export default {
  namespaced: true,
  state: {
    accountCommodityData: []
  },
  getters: {
    getCarouselItems(state) {
      return state.accountCommodityData;
    }
  },
  mutations: {
    setAccountComodityInfo(state, payload) {
      state.accountCommodityData = payload;
    }
  },
  actions: {
    async getAllAccountComodityInfo({ commit, rootGetters }, params) {
      try {
        const response = await AccountComodityService.getAllAccountComodityInfo(params);
        commit("setAccountComodityInfo", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createAccountCommodity({ rootGetters }, payload) {
      try {
        const response = await AccountComodityService.createAccountCommodity(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteAccountCommodity({ rootGetters }, id) {
      try {
        const response = await AccountComodityService.deleteAccountCommodity(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateAccountCommodity({ rootGetters }, payload) {
      try {
        const response = await AccountComodityService.updateAccountCommodity(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAccountCommodityById({ rootGetters, getters }, id) {
      try {
        let response = getters.getOneAccount(id);
        if (!response) {
          response = await AccountComodityService.getAccountCommodityById(id);
        }
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    }
  },
};
