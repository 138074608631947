/* eslint-disable */
import { denyRouteAccess } from "../routerHelpers";

const accountManagementRoutes = [
  {
    path: "account-management",
    name: "AccountManagement",
    component: () => import("../../views/AccountManagement/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
    },
    children: [
      {
        path: "accounts",
        name: "Accounts",
        meta: {
          title: "Account Management | Portal",
        },
        component: () => import("../../views/AccountManagement/children/Accounts/Index.vue"),
      },
      {
        path: "roles/:rolePermisionId?",
        name: "RolePermissions",
        component: () => import("../../views/AccountManagement/children/RolePermissions/Index.vue"),
      },
      {
        path: "roles/:roleId?/permissions",
        name: "Permissions",
        component: () => import("../../views/AccountManagement/children/RolePermissions/Permissions.vue"),
      },
    ],
  },
  {
    path: "account-management/accounts/:accountId/users/:userId?",
    name: "UserManagement",
    meta: {
      title: "User Management | Portal",
    },
    component: () => import("../../views/UserManagement/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
    },
  },
  {
    path: "account-management/new-account",
    name: "CreateNewAccount",
    meta: {
      title: "Create Account | Portal",
    },
    component: () => import("../../views/AccountManagement/children/Accounts/CreateNewAccount.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
    },
  },
];

export default accountManagementRoutes;
