import ls from "@/plugins/secureLs";
import modesList from "../views/QuoteBook/modes";
import DecodeToken from "../utils/DecodeToken";
import LoadStatusType from "../Extend/LoadStatusType";
import Template from "../utils/LoadingTemplate";

const store = require("@/store");

const modesKeys = Object.keys(modesList);
export const modes = modesKeys.map((key) => modesList[key].id);

export async function identifyLoadStatus(loadId) {
  let load = store.default.getters["load/getChosenLoad"];
  if (!load) {
    load = await store.default.dispatch("load/getLoadById", loadId);
  }
  const loadStatus = LoadStatusType.methods.setLoadStatusColor(load.load_status);
  const statusIsQuoted = loadStatus.label.toLowerCase() === "quoted";
  return statusIsQuoted;
}

// ************** Token functions ************** \\
export function getDecodedToken() {
  const portalToken = ls.get("portal-front-token");
  return DecodeToken(portalToken);
}

export function getTokenUserInfo() {
  const portalToken = ls.get("portal-front-token");
  const decodedToken = DecodeToken(portalToken);
  return JSON.parse(decodedToken.sub);
}

export async function getMagayaToken(query) {
  await store.default.dispatch("login/signInMagaya", query);
}

export function validateMagayaGUID(query) {
  const queryParams = Object.keys(query);
  if (queryParams.length && queryParams.includes("magaya_guid")) {
    return true;
  }
  return false;
}

// ************** Route access functions ************** \\
export function denyRouteAccess(to, next) {
  const sidebarOptions = store.default.getters.getSidebarOptions;
  const pathRoute = to.path.slice(1);
  if (!sidebarOptions.find((option) => pathRoute.match(option.pathRoute))) {
    next("/");
    return;
  }
  next();
}

export function denyRouteToBrandingAccounts(next, route) {
  const isBranded = getTokenUserInfo().is_branded;
  if (!isBranded) {
    next();
    return;
  }
  next(route || "/settings");
}

export function denyRouteBrandingAccess(next) {
  const role = getTokenUserInfo().role.toLowerCase();
  const isBranded = getTokenUserInfo().is_branded;
  if (role === "account admin" && isBranded) {
    next();
    return;
  }
  next("/settings");
}

export function allowSettingAccess(roles) {
  return roles.includes(getTokenUserInfo().role.toLowerCase());
}

// ************** Loading template functions ************** \\

const loadingContainer = document.createElement("div");
let appContainer;

function addLoadingTemplateToDOM(container) {
  loadingContainer.innerHTML = Template;
  container.appendChild(loadingContainer);
}

export function removeAppContainer() {
  appContainer = document.querySelector(".app-content");
  if (appContainer) {
    appContainer.lastChild.style.padding = 0;
    appContainer.lastChild.innerHTML = "";
  } else {
    appContainer = document.body;
  }
  addLoadingTemplateToDOM(appContainer);
}

export function removeLoadingTemplateFromDOM() {
  if (appContainer && appContainer.querySelector(".loading-container")) {
    appContainer.removeChild(loadingContainer);
  }
}
