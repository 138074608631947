import api from "../clients/axios";

const resource = 'accessorials'; // Check the route in the backend docs

export default {
  async getAllAccessorials(departmentId) {
    const response = await api.get(`${resource}`, { params: { department_id: departmentId } });
    return response.data;
  },
};
