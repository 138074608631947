import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const standardizers = Repository.service("standardizers");

export default {
  namespaced: true,
  state: {
    nmfcNumber: null,
  },
  getters: {
    getNmfcNumber(state) {
      return state.nmfcNumber;
    },
  },
  mutations: {
    setNmfcNumber(state, payload) {
      state.nmfcNumber = payload;
    },
  },
  actions: {
    async getNmfcStandardizers({ commit, rootGetters }, data) {
      try {
        const response = await standardizers.getNmfcStandardizers(data);
        commit("setNmfcNumber", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async calculateLineItemsInfo({ rootGetters }, data) {
      try {
        const response = await standardizers.calculateLineItemsInfo(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
