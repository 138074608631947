/* eslint-disable consistent-return */
// import Router from "@/router";
import swal from "sweetalert";
import stops from "./modules/stops";
import accessorials from "./modules/accessorials";
import lineItems from "./modules/lineItems";
import tender from "./modules/tender";
import docsGenerate from "./modules/docsGenerate";
import { errorMessage } from "../../../utils/ErrorMessages";
import Repository from "../../../services/repositories/RepositoryFactory";

const load = Repository.service("load");
const clone = Repository.service("clone");

export default {
  namespaced: true,
  state: {
    loads: null,
    chosenLoad: null,
    chosenLoadDetailsView: null,
    melioLinkToPay: null,
    searchText: "",
    updatingRequestInProcess: false,
    clearLoadInformation: 0,
    groundShipment: true,
    markups: {},
    loadWasInsured: false,
  },
  getters: {
    getLoads(state) {
      return state.loads;
    },
    getChosenLoad(state) {
      return state.chosenLoad;
    },
    getChosenLoadDetailsView(state) {
      return state.chosenLoadDetailsView;
    },
    getSearchText(state) {
      return state.searchText;
    },
    getUpdatingRequestInProcess(state) {
      return state.updatingRequestInProcess;
    },
    getClearLoadInformation(state) {
      return state.clearLoadInformation;
    },
    getGroundShipment(state) {
      return state.groundShipment;
    },
    getLoadWasInsured(state) {
      return state.loadWasInsured;
    },
    getAppliedMarkups(state) {
      return state.markups;
    },
  },
  mutations: {
    setAppliedMarkups(state, payload) {
      state.markups = payload;
    },
    setLoadInsurance(state, payload) {
      state.loadWasInsured = payload;
    },
    modifyChosenLoadSpecificData(state, payload) {
      if (state.chosenLoad) {
        state.chosenLoad[payload.key] = payload.value;
      }
    },
    modifyQuoteSpecificData(state, payload) {
      state.chosenLoadDetailsView[payload.key] = payload.value;
    },
    setLoads(state, payload) {
      state.loads = payload;
    },
    setChosenLoad(state, payload) {
      state.chosenLoad = payload;
    },
    setChosenLoadDetailsView(state, payload) {
      state.chosenLoadDetailsView = payload;
    },
    setLinkPayMelioLink(state, payload) {
      state.melioLinkToPay = payload;
    },
    resetChosenload(state) {
      state.chosenLoad = null;
    },
    clearLoadDetailInfo(state) {
      state.chosenLoadDetailsView = null;
    },
    updateCargoValue(state, payload) {
      state.chosenLoad.cargo_value_for_insurance = payload;
    },
    setSearchText(state, payload) {
      state.searchText = payload;
    },
    setUpdatingRequestInProcess(state, payload) {
      state.updatingRequestInProcess = payload;
    },
    updateStopInformation(state, payload) {
      const modifiedStopIndex = state.chosenLoad.stops.findIndex((stop) => stop.id === payload.id);
      state.chosenLoad.stops[modifiedStopIndex] = payload;
    },
    updateAccountsAndContactsLists(state, payload) {
      Object.keys(payload.body).forEach((key) => {
        state.chosenLoad.stops.find((el) => el.id === payload.stop_id)[key] = payload.body[key];
      });
      const location = {
        id: payload.body.location_id,
        name: payload.body.name,
        shipping_address: {
          ...payload.body,
          postal_code: payload.body.zip_code,
        },
      };
      state.chosenLoad.accounts.push(location);
      state.chosenLoad.contacts.push(payload.contact);
    },
    setLocationToLoad(state, payload) {
      const foundLocation = state.chosenLoad.accounts.find((el) => el.id === payload.id);
      if (!foundLocation) {
        state.chosenLoad.accounts.push(payload);
      }
    },
    setContactToLoad(state, payload) {
      const foundContact = state.chosenLoad.contacts.find((el) => el.id === payload.id);
      if (!foundContact) {
        state.chosenLoad.contacts.push(payload);
      }
    },
    setClearLoadInformation(state) {
      state.clearLoadInformation += 1;
    },
    setGroundShipment(state, payload) {
      state.groundShipment = payload;
    },
    resetGroundShipment(state) {
      state.groundShipment = true;
    },
  },
  actions: {
    async getAllLoads({ commit, rootGetters }, data) {
      try {
        const response = await load.getAllLoads(data, rootGetters.getAbortController);
        commit("setLoads", response);
        commit("setCancelRequest", null, { root: true });
        return response;
      } catch (error) {
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async getLoadById({ commit, rootGetters }, id) {
      try {
        const response = await load.getLoadById(id);
        commit("setChosenLoad", response);
        return response;
      } catch (error) {
        // if (error.response.status === 401) {
        //   Router.push({ name: "Login" });
        // }
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getLoadByIdLoadDetailsView({ commit, rootGetters }, id) {
      try {
        const response = await load.getLoadByIdLoadDetailsView(id);
        commit("setChosenLoadDetailsView", response);
        return response;
      } catch (error) {
        // if (error.response.status === 401) {
        //   Router.push({ name: "Login" });
        // }
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createLoad({ rootGetters }, payload) {
      try {
        const response = await load.createLoad(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateLoad({ rootGetters }, body) {
      try {
        const response = await load.updateLoad(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async requoteLoad({ rootGetters }, payload) {
      try {
        const response = await load.requoteLoad(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async cloneLoad({ rootGetters }, payload) {
      try {
        const response = await clone.cloneLoad(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getLoadsAdvanced({ commit, rootGetters }, data) {
      try {
        const response = await load.getLoadsAdvanced(data, rootGetters.getAbortController);
        commit("setLoads", response);
        return response;
      } catch (error) {
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async updateLoadMarkups({ rootGetters }, payload) {
      try {
        const response = await load.updateLoadMarkups(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    /* Melio */
    async getPayMelioLink({ commit, rootGetters }, id) {
      try {
        const response = await load.getPayMelioLink(id);
        commit("setLinkPayMelioLink", response);
        return response;
      } catch (error) {
        if (error.response.status === 400) {
          swal({
            title: "Online payment for this load is not available",
            text: "Your invoice will be ready soon.",
            icon: "warning",
            dangerMode: false,
          });
          return;
        }
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateBillingPO({ rootGetters }, payload) {
      try {
        const response = await load.updateBillingPO(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async sendBilling({ rootGetters }, payload) {
      try {
        const response = await load.sendBilling(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateResidentialAccessorial({ rootGetters }, payload) {
      try {
        const response = await load.updateResidentialAccessorial(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    }
  },
  modules: {
    stops,
    accessorials,
    lineItems,
    docsGenerate,
    tender,
  },
};
