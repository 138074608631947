import { errorMessage, generalMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const quoteFile = Repository.service("quoteFile");

export default {
  namespaced: true,
  state: {
    markups: {
      markup_min: 0,
      markup_percent: 0,
    },
  },
  getters: {
    getMarkups(state) {
      return state.markups;
    },
  },
  mutations: {
    setMarkups(state, payload) {
      state.markups.markup_min = payload.markup_min;
      state.markups.markup_percent = payload.markup_percent;
    },
  },
  actions: {
    async getQuoteFiles({ rootGetters }, payload) {
      try {
        const response = await quoteFile.getQuoteFiles(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getQuoteFileExcel({ rootGetters }, payload) {
      try {
        const response = await quoteFile.getQuoteFileExcel(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async sendQuoteFile({ rootGetters }, payload) {
      try {
        const response = await quoteFile.sendQuoteFile(payload);
        generalMessage(response[rootGetters.getLanguage]);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async sendQuoteFileExcel({ rootGetters }, payload) {
      try {
        const response = await quoteFile.sendQuoteFileExcel(payload);
        generalMessage(response[rootGetters.getLanguage]);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
