import api from "../clients/axios";

const resource = 'account-commodity';

export default {
  async getAllAccountComodityInfo(params) {
    const response = await api.get(`${resource}`, { params });
    return response.data;
  },
  async createAccountCommodity(payload) {
    const response = await api.post(`${resource}`, payload);
    return response;
  },
  async deleteAccountCommodity(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
  async updateAccountCommodity(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, payload.body);
    return response.data;
  },
  async getAccountCommodityById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
};
