import api from '../clients/axios';

const resource = 'trucks-types';

async function getAllTruckTypes(params) {
  const response = await api.get(`${resource}`, params);
  return response.data;
}
async function createTruckType(formData) {
  const response = await api.post(`${resource}`, formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    });
  return response;
}
async function updateTruckType(params) {
  const response = await api.patch(`${resource}/${params.id}`, params.body);
  return response;
}
async function updateTruckTypeImage(params) {
  const response = await api.patch(`${resource}/${params.id}/update-image`, params.formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    });
  return response;
}
async function deleteTruckType(id) {
  const response = await api.delete(`${resource}/${id}`);
  return response;
}

export default {
  getAllTruckTypes,
  deleteTruckType,
  updateTruckType,
  updateTruckTypeImage,
  createTruckType
};
