/* eslint-disable consistent-return */
import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const salesforce = Repository.service("salesforce");

export default {
  namespaced: true,
  state: {
    customers: [],
    requestCancelled: false,
  },
  getters: {
    getCustomers(state) {
      return state.customers;
    },
    getRequestCancelled: (state) => state.requestCancelled,
  },
  mutations: {
    setCustomers(state, payload) {
      state.customers.push(payload);
    },
    setRequestCancelled(state, payload) {
      state.requestCancelled = payload;
    }
  },
  actions: {
    async getAllCustomers({ commit, rootGetters }, payload) {
      try {
        const response = await salesforce.getAllCustomers(payload, rootGetters.getAbortController);
        commit("setRequestCancelled", false);
        commit("setCustomers", response);
        commit("setCancelRequest", null, { root: true });
        return response;
      } catch (error) {
        commit("setRequestCancelled", true);
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
  },
};
