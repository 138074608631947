/* eslint-disable consistent-return */
import { errorMessage } from "../../../../utils/ErrorMessages";
import Repository from "../../../../services/repositories/RepositoryFactory";

const locations = Repository.service("locations");
const load = Repository.service("load");
const salesforceContact = Repository.service("salesforceContact");
const salesforceAccount = Repository.service("salesforceAccount");
const magaya = Repository.service("magaya");

export default {
  namespaced: true,
  state: {
    pickupStop: null,
    deliveryStop: null,
    contact: {
      pickup: null,
      delivery: null,
    },
    zipCode: {
      pickup: null,
      delivery: null,
    },
    locations: null,
    salesforceContacts: null,
    salesforceContactsByAccount: null,
    salesforceAccounts: null,
    requestCancelled: false,
  },
  getters: {
    getStopsList(state) {
      return [state.pickupStop, state.deliveryStop];
    },
    getPickupStop(state) {
      return state.pickupStop;
    },
    getDeliveryStop(state) {
      return state.deliveryStop;
    },
    getSalesforceContacts(state) {
      return state.salesforceContacts;
    },
    getSalesforceAccounts(state) {
      return state.salesforceAccounts;
    },
    getContact(state) {
      return state.contact;
    },
    getZipCode(state) {
      return state.zipCode;
    },
  },
  mutations: {
    setPickupStop(state, payload) {
      state.pickupStop = payload;
    },
    setDeliveryStop(state, payload) {
      state.deliveryStop = payload;
    },
    setAllLocations(state, payload) {
      state.locations = payload;
    },
    setSalesforceContacts(state, payload) {
      state.salesforceContacts = payload;
    },
    setAllSalesforceContactsByAccount(state, payload) {
      state.salesforceContactsByAccount = payload;
    },
    setAllSalesforceAccounts(state, payload) {
      state.salesforceAccounts = payload;
    },
    setNewSalesforceAccount(state, payload) {
      state.salesforceAccounts.push(payload);
    },
    deleteSalesforceAccount(state, accountId) {
      // eslint-disable-next-line max-len
      state.salesforceAccounts = state.salesforceAccounts.filter((account) => account.id !== accountId);
    },
    setContact(state, payload) {
      state.contact[payload.type] = payload.contact;
    },
    setZipCode(state, payload) {
      state.zipCode[payload.type] = payload.zipCode;
    },
    resetChosenStops(state) {
      state.pickupStop = null;
      state.deliveryStop = null;
      state.zipCode = {
        pickup: null,
        delivery: null,
      };
    },
    setRequestCancelled(state, payload) {
      state.requestCancelled = payload;
    }
  },
  actions: {
    async getAllLocations({ commit, rootGetters }, data) {
      try {
        const response = await locations.getAllLocations(data, rootGetters.getAbortController);
        commit("setCancelRequest", null, { root: true });
        commit("setRequestCancelled", false);
        commit("setAllLocations", response);
        return response;
      } catch (error) {
        commit("setRequestCancelled", true);
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async getMagayaClients({ commit, rootGetters }, data) {
      try {
        const response = await magaya.getMagayaClients(data, rootGetters.getCancelToken);
        commit("setCancelRequest", null, { root: true });
        commit("setRequestCancelled", false);
        commit("setAllLocations", response);
        return response;
      } catch (error) {
        commit("setRequestCancelled", true);
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async updateStop({ rootGetters }, data) {
      try {
        const response = await load.updateStop(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getSalesforceContactsByAccount({ commit, rootGetters }, data) {
      try {
        /* eslint-disable max-len */
        const response = await salesforceContact.getSalesforceContactsByAccount(data, rootGetters.getAbortController);
        commit("setCancelRequest", null, { root: true });
        commit("setRequestCancelled", false);
        commit("setAllSalesforceContactsByAccount", response);
        return response;
      } catch (error) {
        commit("setRequestCancelled", true);
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async getContactsByMagayaClient({ commit, rootGetters }, data) {
      try {
        /* eslint-disable max-len */
        const response = await magaya.getContactsByMagayaClient(data, rootGetters.getCancelToken);
        commit("setCancelRequest", null, { root: true });
        commit("setRequestCancelled", false);
        commit("setAllSalesforceContactsByAccount", response);
        return response;
      } catch (error) {
        commit("setRequestCancelled", true);
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async createSalesforceContacts({ rootGetters }, data) {
      try {
        const response = await salesforceContact.createSalesforceContacts(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteSalesforceContact({ rootGetters }, id) {
      try {
        const response = await salesforceContact.deleteSalesforceContact(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getSalesforceAccounts({ commit, rootGetters }, data) {
      try {
        const response = await salesforceAccount.getSalesforceAccounts(data);
        commit("setAllSalesforceAccounts", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createSalesforceAccounts({ rootGetters }, data) {
      try {
        const response = await salesforceAccount.createSalesforceAccounts(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateSalesforceAccount({ rootGetters }, payload) {
      try {
        const response = salesforceAccount.updateSalesforceAccount(payload);
        return response;
      } catch (error) {
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async deleteSalesforceAccount({ rootGetters, commit }, id) {
      try {
        const response = await salesforceAccount.deleteSalesforceAccount(id);
        commit("deleteSalesforceAccount", id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
