import Repository from "../../../../services/repositories/RepositoryFactory";
import { errorMessage } from "../../../../utils/ErrorMessages";

const docGenerate = Repository.service("docsGenerate");

function getOriginalDocumentNameAndExtension(itemUrl) {
  const decodedUrl = decodeURIComponent(itemUrl);
  const fileNameFragments = decodedUrl.split("/");
  const fileNameLastFragment = fileNameFragments[fileNameFragments.length - 1];
  const fileName = fileNameLastFragment.split("?")[0];
  return fileName;
}

function getDocumentName(itemUrl) {
  let docType = "";
  if (itemUrl.includes("/bol/")) {
    docType = "bol";
  } else if (itemUrl.includes("/shipping_labels/")) {
    docType = "shipping_labels";
  } else if (itemUrl.includes("WEIGHT_CERTIFICATE")) {
    docType = "weight_certificate";
  } else if (itemUrl.includes("/portal-tender-documents/")) {
    const formattedDocumentName = getOriginalDocumentNameAndExtension(itemUrl);
    return `${formattedDocumentName}`;
  } else if (itemUrl.includes("BILL_OF_LADING")) {
    docType = "signed_bol";
  } else if (itemUrl.includes("DELIVERY_RECEIPT")) {
    docType = "pod";
  }
  const fileName = getOriginalDocumentNameAndExtension(itemUrl);
  return docType ? `${docType}-${fileName}` : fileName;
}

function defineDocumentsName(documents) {
  const formatedDocuments = documents.map((el) => ({
    ...el,
    bucketName: el.name,
    name: getDocumentName(el.name),
  }));
  return formatedDocuments;
}

export default {
  namespaced: true,
  state: {
    document: null,
    invoiceDocument: [],
    generatedDocs: {
      notify: false,
      shp: null,
    },
    attachmentsDocument: [],
  },
  getters: {
    getDocument(state) {
      return state.document;
    },
    getGeneratedDocs(state) {
      return state.generatedDocs;
    },
    getInvoiceDocument(state) {
      return state.invoiceDocument;
    },
    getAttachmentsDocument(state) {
      return state.attachmentsDocument;
    },
  },
  mutations: {
    setDocument(state, payload) {
      const formatedDocuments = defineDocumentsName(payload);
      state.document = formatedDocuments;
    },
    documentsGeneratedInfo(state, payload) {
      state.generatedDocs[payload.key] = payload.value;
    },
    addDocument(state, payload) {
      if (payload.url) {
        state.document.push({ url: payload.url, name: getDocumentName(payload.url) });
      }
    },
    addInvoiceDocument(state, payload) {
      state.invoiceDocument = payload;
    },
    setAttachmentDocument(state, payload) {
      state.attachmentsDocument = payload;
    },
  },
  actions: {
    async getDocumentById({ rootGetters }, data) {
      try {
        const response = await docGenerate.getDocsByLoadId(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage].msg);
      }
    },
    async generateDocsByLoadId({ commit, rootGetters }, data) {
      try {
        const response = await docGenerate.generateDocsByLoadId(data);
        if (response.length) {
          commit("documentsGeneratedInfo", {
            key: "notify",
            value: true,
          });
          commit("documentsGeneratedInfo", {
            key: "shp",
            value: data.name,
          });
        }
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage].msg);
      }
    },
    async getDocuments({ rootGetters }, data) {
      try {
        const response = await docGenerate.getDocuments(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage].msg);
      }
    },
    async getInvoiceDocByLoadId({ commit, rootGetters }, data) {
      try {
        const response = await docGenerate.getInvoiceDocument(data);
        commit("addInvoiceDocument", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage].msg);
      }
    },
    async getAttachmentsDocs({ rootGetters, commit }) {
      try {
        const response = await docGenerate.getAttachmentsDocs();
        commit("setAttachmentDocument", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage].msg);
      }
    },
  },
};
