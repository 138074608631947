const template = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="stylesheet" href="styles.css" />
    <title>Document</title>
    <style>
      main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        color: #01223f;
        font-family: 'Montserrat';
      }
      .loading-container {
        display: grid;
        width: 300px;
        height: 300px;
      }
      .icon-container {
        position: relative;
        width: 100%;
      }

      .loading-icon {
        position: absolute;
        transform-origin: center;
        animation: run 1.50s ease-in-out infinite alternate;
        top: 62%;
      }
      @-webkit-keyframes run {
        0% {
          left: 36%;
        }
        50% {
          left: 47%;
        }
        100% {
          left: 36%;
        }
      }
      .text-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
      }
      .text-container__text {
        font-size: 16px;
        font-weight: 600;
      }
    </style>
  </head>
  <body>
    <main>
      <div class="loading-container">
        <div class="icon-container">
          <div class="loading-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="currentColor"
              class="bi bi-truck"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
              />
            </svg>
          </div>
        </div>
        <div class="text-container">
          <h1 class="text-container__text">Loading...</h1>
        </div>
      </div>
    </main>
  </body>
</html>
`;

export default template;
