import SignIn from "./SignIn";
import User from "./User";
import Account from "./Account";
import Load from "./Load";
import PaymentHub from "./PaymentHub";
import Accessorials from "./Accessorials";
import LoadAccessorials from "./LoadAccessorials";
import Stops from "./Stops";
import Locations from "./Locations";
import Standardizers from "./Standardizers";
import Carriers from "./Carriers";
import Claims from "./Claims";
import SalesforceAccount from "./SalesforceAccount";
import SalesforceContact from "./SalesforceContact";
import DocsGenerate from "./DocsGenerate";
import Role from "./Role";
import RolePermissions from "./RolePermissions";
import Permissions from "./Permissions";
import TruckTypes from "./TruckTypes";
import Pcmiller from "./Pcmiller";
import History from "./History";
import Share from "./Share";
import CarriersAlert from "./CarriersAlert";
import Salesforce from "./Salesforce";
import ApiKey from "./ApiKey";
import QuoteFile from "./QuoteFile";
import Home from "./Home";
import Carousel from "./Carousel";
import Time from "./Time";
import Magaya from "./Magaya";
import Clone from "./Clone";
import RequestDemo from "./RequestDemo";
import Cancellation from "./Cancellation";
import Insurance from "./Insurance";
import AccountChildren from "./AccountChildren";
import AccountCommodity from "./AccountCommodity";
import Statistics from "./Statistics";
import Address from "./Address";

const repositories = {
  signIn: SignIn,
  user: User,
  account: Account,
  load: Load,
  paymentHub: PaymentHub,
  accessorials: Accessorials,
  loadAccessorials: LoadAccessorials,
  stops: Stops,
  locations: Locations,
  standardizers: Standardizers,
  carriers: Carriers,
  claims: Claims,
  salesforceAccount: SalesforceAccount,
  salesforceContact: SalesforceContact,
  docsGenerate: DocsGenerate,
  role: Role,
  rolePermissions: RolePermissions,
  permissions: Permissions,
  truckTypes: TruckTypes,
  pcmiller: Pcmiller,
  history: History,
  share: Share,
  carriersAlert: CarriersAlert,
  salesforce: Salesforce,
  apiKey: ApiKey,
  quoteFile: QuoteFile,
  home: Home,
  carousel: Carousel,
  time: Time,
  magaya: Magaya,
  clone: Clone,
  requestDemo: RequestDemo,
  cancellation: Cancellation,
  accountChildren: AccountChildren,
  insurance: Insurance,
  accountCommodity: AccountCommodity,
  statistics: Statistics,
  address: Address
};

export default {
  service: (name) => repositories[name],
};
