import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const apiKey = Repository.service("apiKey");

export default {
  namespaced: true,
  state: {
    currentClientApiKeys: [],
    allApiKeys: [],
  },
  getters: {
    getAllApiKeys(state) {
      return state.allApiKeys;
    },
  },
  mutations: {
    setCurrentClientApiKeys(state, payload) {
      state.currentClientApiKeys = [...state.currentClientApiKeys, ...payload];
    },
    setAllApiKeys(state, payload) {
      state.allApiKeys = [...payload];
    },
    modifyApiKey(state, payload) {
      const keyIndex = state.allApiKeys.findIndex((item) => item.id === payload.id);
      state.allApiKeys[keyIndex] = payload;
    },
  },
  actions: {
    async getApiKeysByCurrentClient({ commit, rootGetters }) {
      try {
        const response = await apiKey.getApiKeysByCurrentClient();
        commit("setCurrentClientApiKeys", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAllApiKeys({ rootGetters }, params) {
      try {
        const response = await apiKey.getAllApiKeys(params);
        // commit("setAllApiKeys", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createApiKey({ rootGetters }, params) {
      try {
        const response = await apiKey.createApiKey(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createCompanyApiKey({ rootGetters }, params) {
      try {
        const response = await apiKey.createCompanyApiKey(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateStatusApiKey({ rootGetters }, body) {
      try {
        const response = await apiKey.updateStatusApiKey(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteApiKey({ rootGetters }, id) {
      try {
        const response = await apiKey.deleteApiKey(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
