export default {
  namespaced: true,
  state: {
    magayaTheme: {
      "--color-primary-company": "#00166B",
      "--color-sidebar": "#00166B",
      "--color-sidebar-selected-option": "#0222AA",
      "--color-sidebar-option-hover": "rgba(171, 178, 185, 0.3)",
      "--color-primary-company-hover": "#010f22",
      "--color-primary-company-lighten": "#044076",
      "--color-primary-trigger-button": "#383B48",
      "--color-primary-trigger-button-hover": "#00a2b3",
      "--color-primary-text-button": "#ffffff",
      "--color-secondary-button": "#00166B",
      "--color-secondary-button-hover": "#00209e",
      "--color-secondary-button-text": "#FFFFFF",
      "--color-secondary-button-border": "#00209e",
      "--color-disabled-button": "#C4C4C4",
      "--color-carrier-quote-button": "#17D76E",
      "--color-carrier-quote-button-hover": "#43f78b",
      "--color-carrier-quote-insurance-button": "#383B48",
      "--color-carrier-quote-insurance-button-hover": "#6b7489",
      "--color-carrier-quote-insurance-button-text": "#FFFFFF",
      // "--color-submit-button-hover": "#43f78b",
      "--color-save-button": "#17D76E",
      "--color-remove-inactive-button": "#FD4B72",
      "--color-button-tag": "#2a2d35",
      "--color-button-tag-hover": "#e6e8ea",
      "--color-selected-button-tag": "#b7b7b7",
      "--color-selected-button-filter": "#00166B",
      "--color-selected-button-filter-hover": "#00209e",
      "--color-selected-button-filter-text": "#FFFFFF",
      "--color-border-container": "#798499",
      "--color-primary-title": "#383B48",
    },
    themes: [],
    chosenTheme: null,
    newTheme: {},
  },
  getters: {
    getGltTheme(state) {
      return state.gltTheme;
    },
    getMagayaTheme(state) {
      return state.magayaTheme;
    },
    getThemes(state) {
      return state.themes;
    },
    getChosenTheme(state) {
      return state.chosenTheme;
    },
    getNewTheme(state) {
      return state.newTheme;
    },
  },
  mutations: {
    setThemes(state, payload) {
      state.themes = payload;
    },
    setChosenTheme(state, payload) {
      state.chosenTheme = payload;
    },
    setNewThemeSchema(state, payload) {
      state.newTheme = JSON.parse(JSON.stringify(payload));
    },
    modifyNewTheme(state, payload) {
      state.newTheme.variables[payload.category].find(
        (item) => item.variable_name === payload.variable_name
      ).color = payload.color;
    },
  },
  actions: {},
};
