import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const apiKey = Repository.service("accountChildren");

export default {
  namespaced: true,
  state: {
    accounts: {},
  },
  getters: {
    getChildrenAccounts: (state) => (accountId) => {
      const foundAccount = state.accounts[accountId];
      if (!foundAccount) {
        return null;
      }
      const chldrenAccounts = foundAccount;
      return chldrenAccounts;
    },
  },
  mutations: {
    setChildrenAccounts(state, payload) {
      state.accounts[payload.account_id] = payload.body;
    },
    addChildAccount(state, payload) {
      state.accounts[payload.account_id].push(payload.body);
    },
    updateChildAccount(state, payload) {
      const index = state.accounts[payload.account_id].findIndex((account) => account.id === payload.id);
      const keys = Object.keys(payload.body);
      keys.forEach((key) => {
        state.accounts[payload.account_id][index][key] = payload.body[key];
      });
    },
    deleteChildAccount(state, payload) {
      const index = state.accounts[payload.account_id].findIndex((account) => account.id === payload.id);
      state.accounts[payload.account_id].splice(index, 1);
    },
  },
  actions: {
    async getAllChildrenAccounts({ rootGetters, getters, commit }, params) {
      try {
        let childrenAccounts = getters.getChildrenAccounts(params.account_id);
        if (!childrenAccounts || childrenAccounts.length === 0) {
          childrenAccounts = await apiKey.getAllChildrenAccounts(params);
          commit("setChildrenAccounts", { account_id: params.account_id, body: childrenAccounts });
        }
        return childrenAccounts;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createChildAccount({ rootGetters }, payload) {
      try {
        const response = await apiKey.createChildAccount(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateChildAccount({ rootGetters }, payload) {
      try {
        const response = await apiKey.updateChildAccount(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteChildAccount({ rootGetters }, id) {
      try {
        const response = await apiKey.deleteChildAccount(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
