export default {
  namespaced: true,
  state: {
    transportModalInformation: {
      isInsurance: null,
      insurance: null,
      accesorials: null,
      portalNotes: null,
      selectedTransport: null,
      insuranceAlert: null
    },
  },
  getters: {
    getTransportModalInformation(state) {
      return state.transportModalInformation;
    },
  },
  mutations: {
    modifyTransportModalInformation(state, payload) {
      state.transportModalInformation[payload.key] = payload.value;
    },
  },
};
