import api from "../clients/axios";

const resource = 'permissions';

export default {
  async getAllPermissions() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
};
