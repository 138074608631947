import api from "../clients/axios";

const resource = 'roles';

export default {
  async getAllRoles() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async getRoleById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
};
