import api from "../clients/axios";

const resource = "statistics";

export default {
  async getCountLoadsByMode(param, abortController) {
    const params = param ? { last_n_months: param } : {};
    const response = await api.get(`${resource}/load-modes`, {
      params,
      signal: abortController.signal
    });
    return response.data;
  },
  async getCountByCarriers(param) {
    const params = param ? { last_n_months: param } : {};
    const response = await api.get(`${resource}/load-carriers`, { params });
    return response.data;
  },
  async getCountLoadsByStatus(param, abortController) {
    const params = param ? { last_n_months: param } : {};
    const response = await api.get(`${resource}/load-status`, {
      params,
      signal: abortController.signal
    });
    return response.data;
  },
  async getCountAccessorialsByName(param) {
    const params = param ? { last_n_months: param } : {};
    const response = await api.get(`${resource}/load-accessorials`, { params });
    return response.data;
  },
  async getCountPayableByStatus(abortController) {
    const response = await api.get(`${resource}/billing`, {
      signal: abortController.signal
    });
    return response.data;
  },
  async getStopByState(param, abortController) {
    const params = param ? { last_n_months: param } : {};
    const response = await api.get(`${resource}/stops-states`, {
      params,
      signal: abortController.signal
    });
    return response.data;
  },
  async getVolumeLoadsMonthly(param, abortController) {
    const params = param ? { last_n_months: param } : {};
    const response = await api.get(`${resource}/load-modes-monthly`, {
      params,
      signal: abortController.signal
    });
    return response.data;
  },
};
