import qs from "qs";
import api from "../clients/axios";

const resource = "v2/quote-insurance";

export default {
  async createInsuranceQuote(payload) {
    const response = await api.post(`${resource}/quote`, payload);
    return response.data;
  },
  async updateInsurance(payload) {
    const response = await api.patch(`${resource}/${payload.id}/requote`, payload.body);
    return response.data;
  },
  async createQuote(payload) {
    const response = await api.post(`${resource}`, payload);
    return response.data;
  },
  async updateQuote(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, payload.body);
    return response.data;
  },
  async getInsuranceById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async getAllInsurances(data) {
    const response = await api.get(`${resource}`, {
      params: data,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    if (response) {
      return response.data;
    }
    return null;
  },
  async generateDocuments(id) {
    const response = await api.get(`${resource}/${id}/files`);
    return response.data;
  },
  async generateUploadSignedUrl(payload) {
    const response = await api.post(`${resource}/${payload.id}`, null, {
      params: { filename: payload.fileName },
    });
    return response.data;
  },
  async updateCertificateNumber(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, null, {
      params: { certificade_id: payload.certificadeId },
    });
    return response.data;
  },
  async shareInsuranceFiles(payload) {
    const response = await api.post(`${resource}/${payload.id}/share-files`, payload.body);
    return response.data;
  },
  async certifyInsurance(payload) {
    const response = await api.post(`${resource}/${payload.id}/certify`, payload.body);
    return response.data;
  },
  async searchInsuranceQuote(payload) {
    const response = await api.get(`${resource}/search`, {
      params: { ...payload },
    });
    return response.data;
  },
  async updateFileToBucket(payload) {
    const response = await fetch(payload.url, {
      method: "PUT",
      body: payload.formData.file,
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
    return response.data;
  },
};
