import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const share = Repository.service("home");

export default {
  namespaced: true,
  actions: {
    async getHomeInfo({ rootGetters }) {
      try {
        const response = await share.getHomeInfo();
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateHomeInfo({ rootGetters }, payload) {
      try {
        const response = await share.updateHomeInfo(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async uploadImage({ rootGetters }, payload) {
      try {
        const response = await share.uploadImage(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    }
  },
};
