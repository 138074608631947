import api from "../clients/axios";

const resource = "salesforce"; // Check the route in the backend docs

export default {
  async getAllCustomers(data, abortController) {
    const response = await api.get(`${resource}/customers`, {
      params: data,
      signal: abortController.signal,
    });
    return response.data;
  },
};
