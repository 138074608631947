import api from "../clients/axios";

const resource = 'locations'; // Check the route in the backend docs

export default {
  async getAllLocations(data, abortController) {
    const response = await api.get(`${resource}`, {
      params: data.params,
      signal: abortController.signal,
    });
    return response.data;
  }
};
