import api from "../clients/axios";

const resource = 'home';

export default {
  async getHomeInfo() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async updateHomeInfo(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, { data: payload.body });
    return response.data;
  },
  async uploadImage(payload) {
    const response = await api.post(`${resource}/upload/image`, payload);
    return response.data;
  }
};
