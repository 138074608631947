import api from "../clients/axios";

const resource = "trucks-types";
const allOptionsResource = "loads";
const carrierDB = "carrier-db";

export default {
  async getAllTruckTypes() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async getAllOptionsCarriers(loadId) {
    const response = await api.get(`${allOptionsResource}/${loadId}/carriers`);
    return response.data;
  },
  async getCarrierById(carrierId) {
    const response = await api.get(`${carrierDB}/${carrierId}`);
    return response.data;
  },
};
