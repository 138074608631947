import { errorMessage, warningMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const paymentHub = Repository.service("paymentHub");

export default {
  namespaced: true,
  state: {
    billings: [],
    history: [],
    historyParams: {
      skip: 0,
      limit: 25,
    },
    newLoadPaymentData: [],
    dataLoaded: false,
    loading: false,
    loadingFile: false,
    loadingPayment: false,
    changeTabSelected: false
  },
  getters: {
    billings: (state) => state.billings,
    history: (state) => state.history,
    loading: (state) => state.loading,
    loadingFile: (state) => state.loadingFile,
    historyParams: (state) => state.historyParams,
    dataLoaded: (state) => state.dataLoaded,
    newLoadPaymentData: (state) => state.newLoadPaymentData,
    loadingPayment: (state) => state.loadingPayment,
    getChangeTabSelected: (state) => state.changeTabSelected
  },
  mutations: {
    setBillings: (state, payload) => {
      state.billings = payload;
    },
    setNewLoadPaymentData: (state, payload) => {
      state.newLoadPaymentData = payload;
    },
    clearBillings: (state) => {
      state.billings = [];
    },
    setHistory: (state, payload) => {
      state.history = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setLoadingFile: (state, payload) => {
      state.loadingFile = payload;
    },
    setHistoryParams: (state, payload) => {
      if (payload.value || payload.key === 'skip') {
        state.historyParams[payload.key] = payload.value;
      } else {
        delete state.historyParams[payload.key];
      }
    },
    setDataLoaded: (state, payload) => {
      state.dataLoaded = payload;
    },
    setLoadingPayment: (state, payload) => {
      state.loadingPayment = payload;
    },
    setChangeTabSelected(state, payload) {
      state.changeTabSelected = payload;
    },
  },
  actions: {
    async getMelioPayStatus({ rootGetters }, id) {
      try {
        const response = await paymentHub.getMelioPayStatus(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getPaymentHistory({ rootGetters, commit, state }, isFiltering = false) {
      const { historyParams, history } = state;
      const { getLanguage } = rootGetters;
      let dataLoaded = false;

      commit("setLoading", true);
      commit("setDataLoaded", false);

      try {
        const response = await paymentHub.getPaymentHistory(historyParams);

        if (!response.length || response.length < historyParams.limit) {
          commit("setDataLoaded", true);
          dataLoaded = true;
        }

        if (dataLoaded) {
          if (history.length === 0) {
            commit("setHistory", Array.from(new Set([...history, ...response])));
          }
        }

        if (isFiltering) {
          commit("setHistory", response);
        } else {
          commit("setHistory", []);
          commit("setHistory", Array.from(new Set([...history, ...response])));
        }

        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][getLanguage]);
      } finally {
        commit("setLoading", false);
      }
    },

    async getPaymentFile({ rootGetters, commit }, params) {
      commit("setLoadingFile", true);
      try {
        const filePath = await paymentHub.getFile(params);
        return filePath[0].url;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      } finally {
        commit("setLoadingFile", false);
      }
    },

    async goToPayment({ rootGetters, commit }, payload) {
      commit("setLoadingPayment", true);
      try {
        const response = await paymentHub.goToPayment(payload);
        return response;
      } catch (error) {
        if (error.response.status === 400) {
          return warningMessage(
            error.response.data.detail.errors[0][rootGetters.getLanguage],
            'If the problem persist, please contact support',
          );
        }
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      } finally {
        commit("setLoadingPayment", false);
      }
    },

    async getAllBillingsToPay({ rootGetters }, params) {
      try {
        const response = await paymentHub.getAllBillingsToPay(params);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },

  },
};
