import api from "../clients/axios";

const resource = 'history';

export default {
  async getAllHistory(id) {
    const response = await api.get(`${resource}?load_id=${id}`);
    return response.data;
  },
};
