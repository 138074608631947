import api from '../clients/axios';

const resource = 'timezone-validation';

async function getTimeZone(params) {
  const response = await api.get(`${resource}`, {
    params
  });
  return response.data;
}

export default {
  getTimeZone,
};
