/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
import SetLogoutPath from "./SetLogoutPath";

const store = require("@/store");

export const themes = {
  glt: {
    style: {
      background: "linear-gradient( -45deg, #00FF00 50%, #043663 50% )",
    },
    name: "glt",
    variables: {
      "--color-primary-company": "#043663",
      "--color-sidebar": "#043663",
      "--color-sidebar-selected-option": "#00b9cc",
      "--color-sidebar-option-hover": "#064174",
      "--color-primary-company-hover": "#01223f",
      "--color-primary-company-lighten": "#044076",
      // Buttons
      "--color-primary-trigger-button": "#00b9cc",
      "--color-primary-trigger-button-hover": "#00a2b3",
      "--color-primary-text-button": "#ffffff",
      "--color-secondary-button": "##FFFFFF",
      "--color-secondary-button-hover": "#00b9cc",
      "--color-secondary-button-text": "#00b9cc",
      "--color-secondary-button-border": "#00b9cc",
      "--color-disabled-button": "#C4C4C4",
      "--color-carrier-quote-button": "#043663",
      "--color-carrier-quote-button-hover": "#043663",
      "--color-carrier-quote-insurance-button": "#00ff00",
      "--color-carrier-quote-insurance-button-hover": "#00ff00",
      "--color-carrier-quote-insurance-button-text": "#043663",
      // "--color-submit-button-hover": "#00a2b3",
      "--color-save-button": "#00b9cc",
      // Account management buttons
      // Quote tags colors
      "--color-button-tag": "#043663",
      "--color-button-tag-hover": "#00b9cc",
      "--color-selected-button-tag": "#00b9cc",
      // Dropdown filters colors
      "--color-selected-button-filter": "#00b9cc",
      "--color-selected-button-filter-hover": "#064174",
      "--color-selected-button-filter-text": "#043663",
      // Borders
      "--color-border-container": "#00b9cc",
      // Titles
      "--color-primary-title": "#043663",
      "--color-remove-inactive-button": "#ca3636",
    },
  },
  magaya: {
    style: {
      background: "linear-gradient( -45deg, #2a6ecb 50%, #011a3e 50% )",
    },
    name: "magaya",
    variables: {
      "--color-primary-company": "#00166B",
      "--color-sidebar": "#00166B",
      "--color-sidebar-selected-option": "#0222AA",
      "--color-sidebar-option-hover": "rgba(171, 178, 185, 0.3)",
      "--color-primary-company-hover": "#010f22",
      "--color-primary-company-lighten": "#044076",
      // Buttons
      "--color-primary-trigger-button": "#383B48",
      "--color-primary-trigger-button-hover": "#6b7489",
      "--color-primary-text-button": "#ffffff",
      "--color-secondary-button": "#00166B",
      "--color-secondary-button-hover": "#00209e",
      "--color-secondary-button-text": "#FFFFFF",
      "--color-secondary-button-border": "#00209e",
      "--color-disabled-button": "#C4C4C4",
      "--color-carrier-quote-button": "#17D76E",
      "--color-carrier-quote-button-hover": "#43f78b",
      "--color-carrier-quote-insurance-button": "#383B48",
      "--color-carrier-quote-insurance-button-hover": "#6b7489",
      "--color-carrier-quote-insurance-button-text": "#FFFFFF",
      // "--color-submit-button-hover": "#43f78b",
      "--color-save-button": "#17D76E",
      // Account management buttons
      "--color-remove-inactive-button": "#FD4B72",
      // Quote tags colors
      "--color-button-tag": "#2a2d35",
      "--color-button-tag-hover": "#e6e8ea",
      "--color-selected-button-tag": "#b7b7b7",
      // Dropdown filters colors
      "--color-selected-button-filter": "#00166B",
      "--color-selected-button-filter-hover": "#00209e",
      "--color-selected-button-filter-text": "#FFFFFF",
      // Borders
      "--color-border-container": "#798499",
      // Titles
      "--color-primary-title": "#383B48",
    },
  },
  theme3: {
    style: {
      background: "linear-gradient( -45deg, orange 50%, pink 50% )",
    },
    name: "theme3",
    variables: {
      "--color-primary-company": "pink",
      "--color-primary-company-hover": "rgb(252, 116, 138)",
      "--color-primary-company-lighten": "#ffdce2",
      "--color-blue-sidebar-hover": "#ffdce2",
    },
  },
};

export const gltDefaultTheme = {
  name: "default",
  variables: {
    texts: [
      {
        color: "#043663",
        display: true,
        variable_name: "--color-primary-company",
        variable_name_shown: "Texts"
      },
      {
        color: "#043663",
        display: true,
        variable_name: "--color-primary-title",
        variable_name_shown: "Titles"
      }
    ],
    borders: [
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-border-container",
        variable_name_shown: "Container borders"
      }
    ],
    buttons: [
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-primary-trigger-button",
        variable_name_shown: "Primary button"
      },
      {
        color: "#00a2b3",
        display: false,
        variable_name: "--color-primary-trigger-button-hover",
        variable_name_shown: "Primary Button Hover"
      },
      {
        color: "#ffffff",
        display: true,
        variable_name: "--color-primary-text-button",
        variable_name_shown: "Primary Button Text"
      },
      {
        color: "#FFFFFF",
        display: true,
        variable_name: "--color-secondary-button",
        variable_name_shown: "Secondary Button"
      },
      {
        color: "#00b9cc",
        display: false,
        variable_name: "--color-secondary-button-hover",
        variable_name_shown: "Secondary Button Hover"
      },
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-secondary-button-text",
        variable_name_shown: "Secondary Button Text"
      },
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-secondary-button-border",
        variable_name_shown: "Secondary Button Border"
      },
      {
        color: "#C4C4C4",
        display: false,
        variable_name: "--color-disabled-button",
        variable_name_shown: "Disabled Button"
      },
      {
        color: "#043663",
        display: true,
        variable_name: "--color-carrier-quote-button",
        variable_name_shown: "Carrier Button"
      },
      {
        color: "#043663",
        display: false,
        variable_name: "--color-carrier-quote-button-hover",
        variable_name_shown: "Carrier Button Hover"
      },
      {
        color: "#00ff00",
        display: true,
        variable_name: "--color-carrier-quote-insurance-button",
        variable_name_shown: "Carrier With Insurance Button"
      },
      {
        color: "#00ff00",
        display: false,
        variable_name: "--color-carrier-quote-insurance-button-hover",
        variable_name_shown: "Carrier With Insurance Button Hover"
      },
      {
        color: "#043663",
        display: true,
        variable_name: "--color-carrier-quote-insurance-button-text",
        variable_name_shown: "Carrier With Insurance Text Button"
      },
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-save-button",
        variable_name_shown: "Save Button"
      },
      {
        color: "#043663",
        display: true,
        variable_name: "--color-button-tag",
        variable_name_shown: "Documents Tag Button"
      },
      {
        color: "#00b9cc",
        display: false,
        variable_name: "--color-button-tag-hover",
        variable_name_shown: "Documents Tag Button Hover"
      },
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-selected-button-tag",
        variable_name_shown: "Documents Tag Selected Button"
      },
      {
        color: "#ca3636",
        display: false,
        variable_name: "--color-remove-inactive-button",
        variable_name_shown: "Remove/Inactive button"
      },
      {
        color: "#01223f",
        display: false,
        variable_name: "--color-primary-company-hover",
        variable_name_shown: "Forgot password button hover (?)"
      },
      {
        color: "#044076",
        display: false,
        variable_name: "--color-primary-company-lighten",
        variable_name_shown: "Drag & drop button hover (?)"
      }
    ],
    sidebar: [
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-sidebar-selected-option",
        variable_name_shown: "Sidebar selected option"
      },
      {
        color: "#043663",
        display: true,
        variable_name: "--color-sidebar",
        variable_name_shown: "Sidebar color"
      },
      {
        color: "#064174",
        display: false,
        variable_name: "--color-sidebar-option-hover",
        variable_name_shown: "Sidebar option hover"
      },
      {
        color: "#00ff00",
        display: true,
        variable_name: "--color-sidebar-shadow",
        variable_name_shown: "Sidebar Shadow Color"
      }
    ],
    checkboxes: [
      {
        color: "#00b9cc",
        display: true,
        variable_name: "--color-selected-button-filter",
        variable_name_shown: "Checkbox Filter button"
      },
      {
        color: "##00a6b7",
        display: false,
        variable_name: "--color-selected-button-filter-hover",
        variable_name_shown: "Checkbox Filter button hover"
      },
      {
        color: "#043663",
        display: true,
        variable_name: "--color-selected-button-filter-text",
        variable_name_shown: "Checkbox Filter button text"
      }
    ]
  }
};

function lightOrDark(color) {
  let r;
  let g;
  let b;
  let newColor;
  if (color.match(/^rgb/)) {
    newColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    [r] = color;
    [g] = color;
    [b] = color;
  } else {
    newColor = +(`0x${color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    )}`
    );
    r = newColor >> 16;
    g = newColor >> 8 & 255;
    b = newColor & 255;
  }
  const hsp = Math.sqrt(
    0.299 * (r * r)
        + 0.587 * (g * g)
        + 0.114 * (b * b)
  );
  if (hsp > 127.5) {
    return 'light';
  }
  return 'dark';
}

export function getThemeVariables(theme) {
  const variables = {};
  Object.keys(theme.variables).forEach((category) => {
    theme.variables[category].forEach((variable) => {
      variables[variable.variable_name] = variable.color;
    });
  });
  return variables;
}

export function setSidebarTextColor(root, sidebarColor) {
  const colorBrightness = lightOrDark(sidebarColor) === "dark" ? "#ffffff" : "#000000";
  root.style.setProperty("--color-sidebar-text", colorBrightness);
}

export function changeTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((variable) => {
    root.style.setProperty(variable, theme[variable]);
    if (variable === "--color-sidebar") {
      setSidebarTextColor(root, theme[variable]);
    }
  });
}

// when the user change the settings color in the thememaker - dinamic change
export function getUserColorsConfig(theme, variables) {
  themes[theme].variables = variables;
  changeTheme(theme);
}

// assign the theme settings of the user has in bbdd
export function useAccountSettingsTheme() {
  // Retrieve info from local storage (if any)
  if (localStorage.getItem("user-settings")) {
    const persistedSettings = JSON.parse(localStorage.getItem("user-settings"));
    store.default.commit("color/setThemes", persistedSettings.themes);
    store.default.commit("color/setChosenTheme", persistedSettings.chosen_theme);
  }
  const themesList = store.default.getters["color/getThemes"];
  const chosenTheme = store.default.getters["color/getChosenTheme"];
  let currentTheme = themesList.find((theme) => theme.name === chosenTheme);
  if (chosenTheme === "default") {
    currentTheme = gltDefaultTheme;
  }
  if (!currentTheme) {
    SetLogoutPath();
  }
  const variables = getThemeVariables(currentTheme);
  changeTheme(variables);
}

export function useMagayaTheme() {
  changeTheme(themes.magaya.variables);
}
