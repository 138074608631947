import { errorMessage } from "@/utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const insurance = Repository.service("insurance");

export default {
  namespaced: true,
  state: {
    insuranceList: null,
    selectedInsurance: null,
    downloadedDocuments: [],
    insuranceQuote: {
      origin_city: "",
      origin_state: "",
      origin_country: "US",
      origin_zip_code: "",
      destination_city: "",
      destination_state: "",
      destination_country: "US",
      destination_zip_code: "",
      insured_value: 0,
      condition: "",
      commodity: "",
      conveyance: "",
      is_domestic: false,
    },
    schemaGenerateCertificate: {
      packing: "",
      type_of_shipment: "",
      marks_or_numbers: "",
      transportation_service: "",
      transportation_service_id: "",
      loss_payable: {
        name: "",
        address: "",
        location_info: "",
        country: "",
      },
      sailing_date: "",
      reference_number: "",
    },
    triggerValidations: 0,
    triggerCertificateValidations: 0,
    renderKeyTable: 0,
  },
  getters: {
    getInsurances(state) {
      return state.insuranceList;
    },
    getSelectedInsurance(state) {
      return state.selectedInsurance;
    },
    // eslint-disable-next-line max-len
    getDocumentsById: (state) => (id) => state.downloadedDocuments.find((document) => document.id === id),
    /* Getters of the components of the quote insurance form */
    getInsuranceQuote(state) {
      return state.insuranceQuote;
    },
    getInsuranceQuoteProperty: (state) => (key) => state.insuranceQuote[key],
    getCertificateDocument: (state, getters) => (id) => {
      const documents = getters.getDocumentsById(id);
      if (documents) {
        // eslint-disable-next-line max-len
        const certificateDocument = documents.documents.find((doc) => doc.name.startsWith("certificate/"));
        return certificateDocument;
      }
      return null;
    },
    /* Validations Quote Insurance */
    getTriggerValidations: (state) => state.triggerValidations,
    getTriggerCertificateValidations: (state) => state.triggerCertificateValidations,
    getRenderKeyTable(state) {
      return state.renderKeyTable;
    },
    getGenerateCertificateData(state) {
      return state.schemaGenerateCertificate;
    },
  },
  mutations: {
    setInsurances(state, payload) {
      state.insuranceList = payload;
    },
    setSelectedInsurance(state, payload) {
      state.selectedInsurance = payload;
    },
    setDownloadedDocuments(state, payload) {
      if (state.downloadedDocuments.find((doc) => doc.id === payload.id)) {
        return;
      }
      state.downloadedDocuments.push({ id: payload.id, documents: payload.documents });
    },
    updateInsuranceQuoteProperty(state, payload) {
      state.insuranceQuote[payload.Key] = payload.value;
    },
    setInsuranceQuote(state, payload) {
      state.insuranceQuote = payload;
    },
    updateInsurance(state, payload) {
      const index = state.insuranceList.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.insuranceList[index] = payload.body;
        state.selectedInsurance = payload.body;
      }
    },
    reRenderTable(state) {
      state.renderKeyTable += 1;
    },
    updateGenerateCertificateProperty(state, payload) {
      state.schemaGenerateCertificate[payload.key] = payload.value;
    },
    setGenerateCertificateData(state, payload) {
      state.schemaGenerateCertificate = payload;
    },
    /* Validations */
    setTriggerValidations(state) {
      state.triggerValidations += 1;
    },
    setTriggerCertificateValidations(state) {
      state.triggerCertificateValidations += 1;
    },
    resetInsuranceCertificate(state) {
      state.schemaGenerateCertificate = {
        packing: "",
        type_of_shipment: "",
        marks_or_numbers: "",
        transportation_service: "",
        transportation_service_id: "",
        loss_payable: {
          name: "",
          address: "",
          location_info: "",
        },
        sailing_date: "",
        reference_number: "",
      };
    },
    resetInsuranceQuote(state) {
      state.insuranceQuote = {
        origin_city: "",
        origin_state: "",
        origin_country: "US",
        origin_zip_code: "",
        destination_city: "",
        destination_state: "",
        destination_country: "US",
        destination_zip_code: "",
        insured_value: 0,
        condition: "",
        commodity: "",
        conveyance: "",
        is_domestic: false,
      };
    },
  },
  actions: {
    async createInsuranceQuote({ rootGetters, commit }, payload) {
      try {
        const response = await insurance.createInsuranceQuote(payload);
        commit("setSelectedInsurance", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateInsuranceQuote({ rootGetters, commit }, payload) {
      try {
        const response = await insurance.updateInsurance(payload);
        commit("setSelectedInsurance", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getInsuranceById({ commit, rootGetters, getters }, id) {
      try {
        let selectedInsurance = getters.getSelectedInsurance;
        if (!selectedInsurance || selectedInsurance.id !== id) {
          selectedInsurance = await insurance.getInsuranceById(id);
          commit("setSelectedInsurance", selectedInsurance);
        }
        return selectedInsurance;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    // eslint-disable-next-line consistent-return
    async generateDocuments({ commit, rootGetters }, id) {
      try {
        const response = await insurance.generateDocuments(id);
        if (response.length > 0) {
          commit("setDownloadedDocuments", { documents: response, id });
        }
        return response.documents || response;
      } catch (error) {
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async generateUploadSignedUrl({ rootGetters }, payload) {
      try {
        const response = await insurance.generateUploadSignedUrl(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    // eslint-disable-next-line consistent-return
    async getAllInsurances({ commit, rootGetters }, data) {
      try {
        const response = await insurance.getAllInsurances(data);
        commit("setInsurances", response);
        return response;
      } catch (error) {
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async updateCertificateNumber({ rootGetters }, payload) {
      try {
        const response = await insurance.updateCertificateNumber(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async shareInsuranceFiles({ rootGetters }, payload) {
      try {
        const response = await insurance.shareInsuranceFiles(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async certifyInsurance({ rootGetters }, payload) {
      try {
        const response = await insurance.certifyInsurance(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async searchInsurance({ rootGetters }, payload) {
      try {
        const response = await insurance.searchInsuranceQuote(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateFileToBucket({ rootGetters }, payload) {
      try {
        const response = await insurance.updateFileToBucket(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
