<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import db from "./utils/db";

export default {
  created() {
    db.deleteIndexedDB("loadCreation");
    db.createDb({ dbName: "quoteBook" });
  },
  watch: {
    "$route.path": {
      async handler() {
        if (this.$route.name && this.$route.name.toLowerCase() === "myloads") {
          this.$store.commit("load/resetChosenload");
        }
        // await this.getMagayaToken();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getMagayaToken() {
      const queryParams = Object.keys(this.$route.query);
      if (queryParams.length) {
        await this.$store.dispatch("login/signInMagaya", this.$route.query);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-primary-company;
  @include font-standart-text;
}
@media (max-width: 992px) {
  body::-webkit-scrollbar {
    display: none;
  }
}
</style>
