export default {
  data() {
    return {
      optionsStatus: [
        {
          item: "Quoted",
          text: "Quoted",
          value: true,
          disabled: false,
          info: ["unassigned", "quotes requested", "quotes received", "quoted"],
        },
        {
          item: "Assigned",
          text: "Assigned",
          value: true,
          disabled: false,
          info: ["tendered", "dispatched", "assigned"],
        },
        {
          item: "In Transit",
          text: "In Transit",
          value: true,
          disabled: false,
          info: ["in transit"],
        },
        {
          item: "Delivered",
          text: "Delivered",
          value: true,
          disabled: false,
          info: ["delivered", "completed"],
        },
        {
          item: "Cancelled",
          text: "Cancelled",
          value: true,
          disabled: false,
          info: ["expired", "declined", "cancelled"],
        },
      ],
    };
  },
  methods: {
    setLoadStatusColor(loadStatus) {
      const foundData = {
        color: "",
        label: "",
      };
      const loadStatusString = loadStatus.toLowerCase();
      switch (loadStatusString) {
        case "unassigned":
          // Quoted
          foundData.label = "Quoted";
          foundData.color = "#3a3a3a";
          break;
        case "quotes requested":
          // Quoted
          foundData.label = "Quoted";
          foundData.color = "#3a3a3a";
          break;
        case "quotes received":
          // Quoted
          foundData.label = "Quoted";
          foundData.color = "#3a3a3a";
          break;
        case "quoted":
          // Quoted
          foundData.label = "Quoted";
          foundData.color = "#3a3a3a";
          break;
        case "pending":
          // Quoted
          foundData.label = "Truck Pending";
          foundData.color = "#153ed5";
          break;
        case "tendered":
          // Assigned
          foundData.label = "Assigned";
          foundData.color = "#00ccff";
          break;
        case "assigned":
          // Assigned
          foundData.label = "Assigned";
          foundData.color = "#00ccff";
          break;
        case "dispatched":
          // Assigned
          foundData.label = "Assigned";
          foundData.color = "#00ccff";
          break;
        case "in transit":
          // In transit
          foundData.label = "In Transit";
          foundData.color = "#ff6600";
          break;
        case "delivered":
          // Delivered
          foundData.label = "Delivered";
          foundData.color = "#336600";
          break;
        case "completed":
          // Delivered
          foundData.label = "Delivered";
          foundData.color = "#336600";
          break;
        case "cancelled":
          // Cancelled
          foundData.label = "Cancelled";
          foundData.color = "#FF0A0A";
          break;
        case "declined":
          // Cancelled
          foundData.label = "Cancelled";
          foundData.color = "#FF0A0A";
          break;
        case "expired":
          // Cancelled
          foundData.label = "Cancelled";
          foundData.color = "#FF0A0A";
          break;
        default:
          // Doesn't match
          foundData.label = "Invalid Load Status Type";
          foundData.color = "grey";
          break;
      }
      return foundData;
    },
    getStatusInfo(loadStatusString) {
      const statusMappings = {
        Quoted: ["unassigned", "quotes requested", "quotes received", "quoted"],
        Assigned: ["tendered", "dispatched", "assigned"],
        "In Transit": ["in transit"],
        Delivered: ["delivered", "completed"],
        Cancelled: ["expired", "declined", "cancelled"],
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const statusItem in statusMappings) {
        if (statusMappings[statusItem].includes(loadStatusString)) {
          return { label: statusItem, color: this.getColorForStatus(statusItem) };
        }
      }
      return null;
    },
    getColorForStatus(status) {
      const colorMap = {
        Quoted: "#3a3a3a",
        Assigned: "#00ccff",
        "In Transit": "#ff6600",
        Delivered: "#336600",
        Cancelled: "#FF0A0A",
      };
      return colorMap[status] || "grey";
    },
  },
};
