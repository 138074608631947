import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";
import Scopes from "../../utils/Scopes";
import login from "./login";

const rolePermissions = Repository.service("rolePermissions");
const permissions = Repository.service("permissions");

export default {
  namespaced: true,
  state: {
    allRolesPermissions: null,
    rolePermissionsById: null,
    allPermissions: null,
    permissions: [],
  },
  getters: {
    getRolesPermissions(state) {
      return state.allRolesPermissions;
    },
    getRolePermissionsById(state) {
      return state.rolePermissionsById;
    },
    getPermissions(state) {
      return state.allPermissions;
    },
    getPermissionsToLoginAccount(state) {
      return state.permissions;
    },
  },
  mutations: {
    setRolesPermissions(state, payload) {
      state.allRolesPermissions = payload;
    },
    setRolePermissionsById(state, payload) {
      state.rolePermissionsById = payload;
    },
    setPermissions(state, payload) {
      state.allPermissions = payload;
    },
    setScopes(state, payload) {
      state.permissions = payload;
    },
    setPermissionsToLoginAccount(state, payload) {
      state.permissions = [];
      switch (payload) {
        case "glt admin":
          state.permissions = Scopes.adminRole;
          break;
        case "account admin":
          // eslint-disable-next-line no-case-declarations
          const { tokenInfo } = login.state;
          /* eslint-disable no-case-declarations, camelcase */
          let { can_dispatch } = JSON.parse(localStorage.getItem("user-settings"));
          const { is_branded } = tokenInfo;
          if (!can_dispatch) {
            can_dispatch = tokenInfo.can_dispatch;
          }
          if (can_dispatch && is_branded) {
            state.permissions = Scopes.accountAdminWithBrandedAndDispatch;
          } else if (can_dispatch && !is_branded) {
            state.permissions = Scopes.accounAdminWithoutBranded;
          } else if (!can_dispatch && is_branded) {
            state.permissions = Scopes.accountAdminWithoutDispatch;
          } else if (!can_dispatch && !is_branded) {
            state.permissions = Scopes.accountAdminWithoutBrandedAndDispatch;
          }
          break;
        case "sales agent":
          break;
        case "costumer agent":
          break;
        default:
          state.permissions = [];
          break;
      }
    },
  },
  actions: {
    async getAllRolePermissions({ commit, rootGetters, getters }) {
      try {
        let response = getters.getRolesPermissions;
        if (!response) {
          response = await rolePermissions.getAllRolePermissions();
        }
        commit("setRolesPermissions", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getRolePermissionsById({ rootGetters }, id) {
      try {
        const response = await rolePermissions.getRolePermissionsById(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createRolePermissions({ rootGetters }, body) {
      try {
        const response = await rolePermissions.createRolePermissions(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteRolePermissions({ rootGetters }, id) {
      try {
        const response = await rolePermissions.deleteRolePermissions(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAllPermissions({ commit, rootGetters, getters }) {
      try {
        let response = getters.getPermissions;
        if (!response) {
          response = await permissions.getAllPermissions();
        }
        commit("setPermissions", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
  module: {
    login
  }
};
