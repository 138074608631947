import api from "../clients/axios";

const resource = 'cancellation';

export default {
  async getCancellationStatus(loadId) {
    const response = await api.get(`${resource}/${loadId}`);
    return response.data;
  },
  async cancelLoad(payload) {
    const response = await api.post(`${resource}/${payload.loadId}`, payload.body);
    return response.data;
  },
};
