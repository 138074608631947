import api from '../clients/axios';

const resource = 'share-files';

async function postShareFile(data) {
  const response = await api.post(`${resource}/${data.id}`, data.body);
  return response.data;
}

export default {
  postShareFile
};
