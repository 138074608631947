import Vue from 'vue';
import VueRouter from 'vue-router';
import LayoutRoute from './modules/layout';
import LoginRoute from './modules/login';

const DEFAULT_TITLE = "Portal";

Vue.use(VueRouter);

const AppRoute = [
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound/Index.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...LoginRoute,
    ...LayoutRoute,
    ...AppRoute,
  ],
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
