import api from "../clients/axios";

const resource = "role-permissions";

export default {
  async getAllRolePermissions() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async getRolePermissionsById(id) {
    const response = await api.get(`${resource}?role_id=${id}`);
    return response.data;
  },
  async createRolePermissions(data) {
    const response = await api.post(`${resource}`, data);
    return response.data;
  },
  async deleteRolePermissions(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
};
