import api from "../clients/axios";

const resource = 'magaya-company';

export default {
  async getIfMagayaCompanyExist(id) {
    const response = await api.get(`${resource}/exist/${id}`);
    return response.data;
  },
  async getMagayaClients(data, cancelToken) {
    const response = await api.get(`magaya-entity/client`, {
      params: data.params,
      ...(cancelToken && { cancelToken: cancelToken.token })
    });
    return response.data;
  },
  async getContactsByMagayaClient(data, cancelToken) {
    const response = await api.get(`magaya-entity/client/contacts`, {
      params: data.params,
      ...(cancelToken && { cancelToken: cancelToken.token })
    });
    return response.data;
  },
  async validateMagayaCredentials(payload) {
    const response = await api.post(`auth/magaya/validate`, payload);
    return response.data;
  },
  async updateMagayaCredentials(payload) {
    const response = await api.patch(`auth/magaya/credentials`, payload);
    return response.data;
  },
};
