/*
//This could be deleted, it is just an example.
import {
  modes
} from "../routerHelpers";
 */
/* function validateInsuranceMode(to, next) {
  if (modes.includes(to.params.quoteType)) {
    next();
  } else {
    next("/404");
  }
} */

const insuranceRoutes = [
  {
    path: "insurance",
    name: "Insurance",
    meta: {
      title: "Insurance",
    },
    redirect: () => ({ name: "QuoteInsurance" }),
    component: () => import("../../views/Insurance/Index.vue"),
    /* Validation route to insurance mode active */
    beforeEnter: async (to, from, next) => {
      const insuranceMode = JSON.parse(localStorage.getItem("user-settings")).can_dispatch_modes
        .insurance;
      if (insuranceMode) {
        next();
      } else {
        next("/404");
      }
    },
    children: [
      {
        path: "quote-insurance/:id?",
        name: "QuoteInsurance",
        meta: {
          title: "Quote Insurance",
        },
        component: () => import("../../views/Insurance/QuoteInsurance/Index.vue"),
        children: [],
      },
      {
        path: "my-insurance",
        name: "MyInsurance",
        meta: {
          title: "My Insurance",
        },
        component: () => import("../../views/Insurance/MyInsurance/Index.vue"),
        children: [],
      },
      {
        path: "my-insurance/:id",
        name: "InsuranceDetails",
        meta: {
          title: "My Insurance",
        },
        component: () => import("../../views/Insurance/InsuranceDetail/Index.vue"),
      },
      {
        path: "insurance-selection/:id",
        name: "InsuranceSelection",
        meta: {
          title: "My Insurance | Rates",
        },
        component: () => import("../../views/Insurance/InsuranceSelection/Index.vue"),
      },
      {
        path: "insurance-certificate/:id",
        name: "GenerateInsuranceCertificate",
        component: () => import("../../views/Insurance/InsuranceCertificate/Index.vue"),
      }
    ],
  },
];

export default insuranceRoutes;
