import api from "../clients/axios";

const resource = "api-key";

export default {
  async getApiKeysByCurrentClient() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async getAllApiKeys(params) {
    const response = await api.get(`${resource}/all`, { params });
    return response.data;
  },
  async createApiKey(params) {
    const response = await api.post(`${resource}`, params);
    return response.data;
  },
  async createCompanyApiKey(body) {
    const response = await api.post(`${resource}/register`, body);
    return response.data;
  },
  async updateStatusApiKey(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, payload.body);
    return response.data;
  },
  async deleteApiKey(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
};
