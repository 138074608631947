import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage } from "../../utils/ErrorMessages";

const history = Repository.service("history");

export default {
  namespaced: true,
  state: {
    history: null,
  },
  getters: {
    getHistory(state) {
      return state.history;
    },
  },
  mutations: {
    setHistory(state, payload) {
      state.history = payload;
    },
  },
  actions: {
    async getAllHistory({ commit, rootGetters }, id) {
      try {
        const response = await history.getAllHistory(id);
        commit("setHistory", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
