import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import axios from "axios";
import i18n from "@/plugins/i18n";
import interceptorsSetup from "@/services/clients/interceptors";
import SecureLS from "secure-ls";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import DeepCopy from "./utils/DeepCopy";

// Vue Shortkey
import "@/plugins/vueShortkey";
// Element
import "@/plugins/element";
// eCharts
import "@/plugins/eCharts";
// Bootstrap Vue
import "@/plugins/bootstrapVue";
// PrimeVue
import "@/plugins/primeVue";
// sweetAlert
import "@/plugins/sweetAlert";
// Leaflet
import "@/plugins/leaflet";
// FILTERS
import "@/filters/day";
import "@/filters/priceToUsd";
import "@/filters/checkPermission";
import "@/filters/insurancePrice";

Vue.use(VueExcelXlsx);

const ls = new SecureLS();

interceptorsSetup();
Vue.prototype.$deepCopy = DeepCopy;
Vue.prototype.$ls = ls;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
