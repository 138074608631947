import api from "../clients/axios";

const resource = "users";

export default {
  async getMyInfo() {
    const response = await api.get(`${resource}/me`);
    return response.data;
  },
  async getAllMyInfo() {
    const response = await api.get(`${resource}/me/complete`);
    return response.data;
  },
  async createAccount(body) {
    const response = await api.post(`${resource}/admin/register`, body);
    return response.data;
  },
  async createUser(body) {
    const response = await api.post(`${resource}/register`, body);
    return response.data;
  },
  async createMagayaUser(body) {
    const response = await api.post(`${resource}/magaya/register`, body, {
      headers: {
        token: "54dccb3747b693e185543f550a2dd280",
      },
    });
    return response.data;
  },
  async forgotPassword(body) {
    const response = await api.post(`${resource}/forgot-password`, body);
    return response.data;
  },
  async recoveryPassword(data) {
    const response = await api.post(`${resource}/recovery-password`, data.body, {
      params: data.params,
    });
    return response.data;
  },
  async validateIfTheEmailExist(data) {
    const response = await api.get(`${resource}/username`, {
      params: {
        username: data.username
      },
    });
    return response.data;
  },
  async getUsers() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
  async updateUserInfo(data) {
    const response = await api.patch(`${resource}/${data.id}`, data.body);
    return response.data;
  },
  async updateUserTour(data) {
    const response = await api.patch(`${resource}/user-tour`, null, {
      params: { section_name: data },
    });
    return response.data;
  },
  async deleteUser(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
};
