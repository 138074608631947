/* eslint-disable */
import { useAccountSettingsTheme, useMagayaTheme } from "../../utils/Themes";
import { initEnablementModes } from "../../views/QuoteBook/modes";
import {
  denyRouteAccess,
  getTokenUserInfo,
  getDecodedToken,
  getMagayaToken,
  validateMagayaGUID,
  removeAppContainer,
  removeLoadingTemplateFromDOM
} from "../routerHelpers";
import AccountManagementRoutes from "./accountManagement";
import QuoteBookRoutes from "./quoteBook";
import Insurance from "./insurance";
import SettingsRoutes from "./settings";
import MyLoadsRoutes from "./myLoads";
import PaymentHub from "./paymentHub";

let tokenUserInfo;

const store = require("@/store");

async function getRequiredAppData() {
  const promises = [
    store.default.dispatch("carriers/getCarriersImages"),
    store.default.dispatch("carriersAlert/getAllCarriersAlert"),
    store.default.dispatch("user/getAllMyInfo"),
  ];
  await Promise.all(promises);
  // The following mutation is used to disable the spinner in login view
  store.default.commit("login/setLoadingLogin", false);
}

function setSidebarOptions() {
  store.default.commit("setSidebarOptions", getTokenUserInfo());
  return store.default.getters.getSidebarOptions;
}

function setAppTheme() {
  if (getTokenUserInfo().magaya_guid) {
    useMagayaTheme();
  } else {
    useAccountSettingsTheme("glt");
  }
}

const LayoutRoute = [
  {
    path: "/",
    name: "Layout",
    component: () => import("../../views/Layout/Index.vue"),
    redirect: (to) => {
      try {
        if (validateMagayaGUID(to.query)) {
          return { name: "MyLoads" };
        }
        const portalToken = localStorage.getItem("portal-front-token");
        if (!portalToken) {
          return { name: "Login" };
        }
        const sidebarOptions = setSidebarOptions();
        return { name: sidebarOptions[0].pathName };
      } catch (error) {
        if (error.message === "Could not parse JSON" || error.name === "TypeError") {
          return { name: "Login" };
        }
      }
      return null;
    },
    beforeEnter: async (to, from, next) => {
      if (validateMagayaGUID(to.query)) {
        await getMagayaToken(to.query);
      }
      try {
        const portalToken = localStorage.getItem("portal-front-token");
        if (!portalToken) {
          next("/login");
        }
        removeAppContainer();
        await getRequiredAppData();
        setSidebarOptions();
        store.default.commit("login/setTokenInfo", tokenUserInfo);
        store.default.commit("rolePermissions/setScopes", getDecodedToken().scopes);
        setAppTheme(getDecodedToken());
        initEnablementModes();
        // Only show loading template when the route is not quote-book
        if (!to.path.match("quote-book")) {
          removeLoadingTemplateFromDOM();
        }
        next();
      } catch (error) {
        if (error.message === "Could not parse JSON" || error.name === "TypeError") {
          next("/login");
        }
      }
    },
    children: [
      // {
      //   path: "home",
      //   name: "Home",
      //   meta: {
      //     title: "Home | Portal",
      //   },
      //   component: () => import("../../views/Home/Index.vue"),
      //   beforeEnter: (to, from, next) => {
      //     denyRouteAccess(to, next);
      //   },
      // },
      {
        path: "home",
        name: "Dashboard",
        meta: {
          title: "Dashboard | Portal"
        },
        component: () => import("../../views/Dashboard/Index.vue"),
        beforeEnter: (to, from, next) => {
          denyRouteAccess(to, next);
        },
      },
      ...MyLoadsRoutes,
      ...AccountManagementRoutes,
      ...Insurance,
      ...QuoteBookRoutes,
      ...SettingsRoutes,
      ...PaymentHub
    ],
  },
];

export default LayoutRoute;
