import { denyRouteToBrandingAccounts } from "../routerHelpers";

const paymentHubRoutes = [
  {
    path: "payment-hub",
    name: "paymentHub",
    meta: {
      title: "Payment Hub | Portal",
    },
    component: () => import("../../views/PaymentHub/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteToBrandingAccounts(next, "/home");
    },
  },
];

export default paymentHubRoutes;
