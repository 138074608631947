// import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const statistics = Repository.service("statistics");

export default {
  namespaced: true,
  state: {
    dataLoadsByStatus: null,
    dataLoadsByMode: null,
    dataTopCarriers: [[], []],
    dataMostUsedAccessorials: [[], []],
    dataPayableByStatus: null,
    dataVolumeLoadsMonthly: null,
    dataMap: null,
    filterMonths: "",
    // userExecuteDashboardTour: false,
    // loadedDashboardInformation: false
  },
  getters: {
    getDataLoadsByMode(state) {
      return state.dataLoadsByMode;
    },
    getDataTopCarriers(state) {
      return state.dataTopCarriers;
    },
    getDataMostUsedAccessorials(state) {
      return state.dataMostUsedAccessorials;
    },
    getDataPaybleByStatus(state) {
      return state.dataPayableByStatus;
    },
    getDataLoadsByStatus(state) {
      return state.dataLoadsByStatus;
    },
    getDataVolumeLoadsMonthly(state) {
      return state.dataVolumeLoadsMonthly;
    },
    getDataMap(state) {
      return state.dataMap;
    },
    // getUserExecuteDashboardTour(state) {
    //   return state.userExecuteDashboardTour;
    // },
    // getLoadedDashboardInformation(state) {
    //   return state.loadedDashboardInformation;
    // }
  },
  mutations: {
    setDataLoadsByMode(state, payload) {
      state.dataLoadsByMode = payload;
    },
    setDataTopCarriers(state, payload) {
      state.dataTopCarriers = payload;
    },
    setDataMostUsedAccessorials(state, payload) {
      state.dataMostUsedAccessorials = payload;
    },
    setDataPaybleByStatus(state, payload) {
      state.dataPayableByStatus = payload;
    },
    setDataLoadsByStatus(state, payload) {
      state.dataLoadsByStatus = payload;
    },
    setDataVolumeLoadsMonthly(state, payload) {
      state.dataVolumeLoadsMonthly = payload;
    },
    setDataMap(state, payload) {
      state.dataMap = payload;
    },
    setFiltersMonth(state, payload) {
      state.filterMonths = payload;
    },
    resetFiltersMonth(state) {
      state.filterMonths = null;
    },
    // setUserExecuteDashboardTour(state, payload) {
    //   state.userExecuteDashboardTour = payload;
    // },
    // setLoadedDashboardInformation(state, payload) {
    //   state.loadedDashboardInformation = payload;
    // }
  },
  actions: {
    async getCountLoadByStatus({ commit, rootGetters }) {
      try {
        commit("setDataLoadsByStatus", null);
        const response = await statistics.getCountLoadsByStatus(3, rootGetters.getAbortController);
        commit("setDataLoadsByStatus", response);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getDataLoadsByMode({ state, commit, rootGetters }) {
      try {
        commit("setDataLoadsByMode", null);
        const response = await statistics.getCountLoadsByMode(
          state.filterMonths,
          rootGetters.getAbortController
        );
        commit("setDataLoadsByMode", response);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getCountByCarriers({ state, commit }) {
      try {
        commit("setDataTopCarriers", [[], []]);
        const response = await statistics.getCountByCarriers(state.filterMonths);
        commit("setDataTopCarriers", response);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getCountAccessorialsByName({ state, commit }) {
      try {
        commit("setDataMostUsedAccessorials", [[], []]);
        const response = await statistics.getCountAccessorialsByName(state.filterMonths);
        commit("setDataMostUsedAccessorials", response);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getCountPayableByStatus({ commit, rootGetters }) {
      try {
        commit("setDataPaybleByStatus", null);
        const response = await statistics.getCountPayableByStatus(rootGetters.getAbortController);
        commit("setDataPaybleByStatus", response);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getVolumeLoadsMonthly({ commit, rootGetters }) {
      try {
        commit("setDataVolumeLoadsMonthly", null);
        const response = await statistics.getVolumeLoadsMonthly(11, rootGetters.getAbortController);
        commit("setDataVolumeLoadsMonthly", response);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getDataOfMap({ state, commit, rootGetters }) {
      try {
        commit("setDataMap", null);
        const response = await statistics.getStopByState(
          state.filterMonths,
          rootGetters.getAbortController
        );
        commit("setDataMap", response);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
