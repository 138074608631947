import ls from "@/plugins/secureLs";
import DecodeToken from "./DecodeToken";

async function setLogoutPath() {
  const baseUrl = window.location.origin;
  const currentToke = ls.get("portal-front-token");
  let routeName = "login";
  if (currentToke) {
    const decodedToken = DecodeToken(ls.get("portal-front-token"));
    const isMagaya = JSON.parse(decodedToken.sub).magaya_guid;
    if (isMagaya) {
      routeName = "login-magaya";
    }
    window.location.href = `${baseUrl}/${routeName}`;
  } else {
    window.location.href = `${baseUrl}/${routeName}`;
  }
}

export default setLogoutPath;
