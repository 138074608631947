import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const role = Repository.service("role");

export default {
  namespaced: true,
  state: {
    allRoles: [
      {
        description: "Platform and account management by the GLT Team",
        name: "GLT Admin",
        is_visible: true,
        id: 1,
        created_at: "2022-09-01T20:37:48.502747+00:00",
        last_modified: "2022-09-01T20:37:48.502766+00:00",
      },
      {
        description: "External account manager ",
        name: "Account Admin",
        is_visible: true,
        id: 2,
        created_at: "2022-09-01T20:37:48.594719+00:00",
        last_modified: "2022-09-01T20:37:48.594740+00:00",
      },
      {
        description: "Account that has permissions to define commission intervals",
        name: "Sales Agent",
        is_visible: true,
        id: 3,
        created_at: "2022-09-01T20:37:48.600258+00:00",
        last_modified: "2022-09-01T20:37:48.600290+00:00",
      },
      {
        description: "Account that has basic permissions",
        name: "Customer Agent",
        is_visible: true,
        id: 4,
        created_at: "2022-09-01T20:37:48.612539+00:00",
        last_modified: "2022-09-01T20:37:48.612559+00:00",
      },
    ],
    roleById: null,
    roleSelected: null,
  },

  getters: {
    getRoles(state) {
      return state.allRoles;
    },
    getRoleById(state) {
      return state.roleById;
    },
    getRoleSelected(state) {
      return state.roleSelected;
    },
  },
  mutations: {
    setRoles(state, payload) {
      state.allRoles = payload;
    },
    setRoleById(state, payload) {
      state.roleById = payload;
    },
    setRoleSelected(state, payload) {
      state.roleSelected = payload;
    },
  },
  actions: {
    async getAllRoles({ commit, rootGetters, getters }) {
      try {
        let response = getters.getRoles;
        if (!response) {
          response = await role.getAllRoles();
        }
        commit("setRoles", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
