import api from "../clients/axios";

const resource = 'request-demo';

export default {
  async sendRequestDemo(payload) {
    const response = await api.post(`${resource}`, payload.body);
    return response.data;
  },
};
