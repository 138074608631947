import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage } from "../../utils/ErrorMessages";

const carriersAlert = Repository.service("carriersAlert");

export default {
  namespaced: true,
  state: {
    allCarriersAlert: null,
  },
  getters: {
    getAllCarriersAlert(state) {
      return state.allCarriersAlert;
    }
  },
  mutations: {
    setAllCarriersAlert(state, payload) {
      state.allCarriersAlert = payload;
    }
  },
  actions: {
    async getAllCarriersAlert({ commit, rootGetters, getters }) {
      try {
        let response = getters.getAllCarriersAlert;
        if (!response) {
          response = await carriersAlert.getAllCarriersAlert();
        }
        commit("setAllCarriersAlert", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createAllCarriersAlert({ rootGetters }, payload) {
      try {
        const response = await carriersAlert.createAllCarriersAlert(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getCarriersAlertById({ rootGetters }, id) {
      try {
        const response = await carriersAlert.getCarriersAlertById(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteCarriersAlertById({ rootGetters }, id) {
      try {
        const response = await carriersAlert.deleteCarriersAlert(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateCarriersAlertById({ rootGetters }, payload) {
      try {
        const response = await carriersAlert.updateCarriersAlert(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
