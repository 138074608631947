import {
  denyRouteBrandingAccess,
  allowSettingAccess,
  denyRouteAccess,
  getTokenUserInfo,
  denyRouteToBrandingAccounts,
} from "../routerHelpers";

const settingsRoutes = [
  {
    path: "settings",
    name: "Settings",
    meta: {
      title: "Settings | Portal",
    },
    component: () => import("../../views/Settings/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
    },
  },
  {
    path: "settings/api-key",
    name: "ApiKeys",
    component: () => import("../../views/Settings/ApiKeys/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (!allowSettingAccess(["glt admin"])) {
        next("/settings");
      }
    },
  },
  {
    path: "settings/account-api-key",
    name: "ClientApiKeys",
    component: () => import("../../views/Settings/ClientApiKeys/Index.vue"),
    beforeEnter: (to, from, next) => {
      if (getTokenUserInfo().magaya_guid || !allowSettingAccess(["account admin"])) {
        next("/settings");
      } else {
        next();
      }
      denyRouteAccess(to, next);
    },
  },
  {
    path: "settings/my-locations/:locationId?",
    name: "MyLocations",
    meta: {
      title: "My Locations | Portal",
    },
    component: () => import("../../views/Settings/MyLocations/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (!allowSettingAccess(["glt admin", "account admin", "customer agent"])) {
        next("/settings");
      }
    },
  },
  /* My Line Items */
  {
    path: "settings/my-line-items/",
    name: "MyLineItems",
    meta: {
      title: "My Line Items | Portal",
    },
    component: () => import("../../views/Settings/MyLineItems/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (!allowSettingAccess(["glt admin", "account admin", "customer agent"])) {
        next("/settings");
      }
    },
  },
  // {
  //   path: "settings/mosaic-setup",
  //   name: "HomeMosaic",
  //   meta: {
  //     title: "Home Mosaic | Portal",
  //   },
  //   component: () => import("../../views/Settings/HomeMosaic/Index.vue"),
  //   beforeEnter: (to, from, next) => {
  //     denyRouteAccess(to, next);
  //     if (getTokenUserInfo().magaya_guid || !allowSettingAccess(["glt admin"])) {
  //       next("/settings");
  //     } else {
  //       next();
  //     }
  //   },
  // },
  {
    path: "settings/:accountId/users/:userId?",
    name: "SettingsUserManagement",
    meta: {
      title: "User Management | Portal",
    },
    component: () => import("../../views/Settings/UserManagement/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (allowSettingAccess(["glt admin"])) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "settings/logos",
    name: "Logos",
    meta: {
      title: " Branding | Portal",
    },
    component: () => import("../../views/Settings/Logos/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (allowSettingAccess(["account admin"])) {
        next();
      } else {
        next("/settings");
      }
    },
  },
  {
    path: "settings/themes",
    name: "Themes",
    meta: {
      title: " Branding | Portal",
    },
    component: () => import("../../views/Settings/Branding/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteBrandingAccess(next, "/settings");
    },
  },
  {
    path: "settings/themes/:theme",
    meta: {
      title: "Theme Maker | Portal",
    },
    name: "ThemeMaker",
    component: () => import("../../views/Settings/Branding/ThemeMaker/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteBrandingAccess(next, "/settings");
    },
  },
  {
    path: "settings/statements",
    name: "Statements",
    meta: {
      title: " Statements | Portal",
    },
    component: () => import("../../views/Settings/Statements/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteToBrandingAccounts(next, "/settings");
      if (getTokenUserInfo().magaya_guid) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "settings/favorite-carriers",
    name: "FavoriteCarriers",
    meta: {
      title: " Favorite Carriers | Portal",
    },
    component: () => import("../../views/Settings/FavoriteCarriers/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (!allowSettingAccess(["glt admin", "account admin", "customer agent"]) || getTokenUserInfo.is_branded) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "settings/email-notifications",
    name: "EmailNotifications",
    meta: {
      title: "Email Notifications | Portal",
    },
    component: () => import("../../views/Settings/EmailNotifications/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (!allowSettingAccess(["glt admin", "account admin"]) || getTokenUserInfo.is_branded) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "settings/mosaic-carousel",
    name: "CarouselMosaic",
    meta: {
      title: "Carousel Mosaic | Portal",
    },
    component: () => import("../../views/Settings/CarouselMosaic/Index.vue"),
    beforeEnter: (to, from, next) => {
      denyRouteAccess(to, next);
      if (getTokenUserInfo().magaya_guid || !allowSettingAccess(["glt admin"])) {
        next("/settings");
      } else {
        next();
      }
    },
  },
];

export default settingsRoutes;
