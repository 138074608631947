import api from "../clients/axios";

const resource = "claims";

export default {
  async getClaimsById(id) {
    const response = await api.get(`${resource}?load_id=${id}`);
    return response.data;
  },
  async createClaims(data) {
    const response = await api.post(`${resource}`, data);
    return response.data;
  },
  async updateClaims(data) {
    const response = await api.patch(`${resource}/${data.id}`, data.body);
    return response.data;
  },
};
