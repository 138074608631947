import api from "../clients/axios";

const melio = "melio";

export default {
  async getMelioPayStatus(loadId) {
    const response = await api.get(`${melio}`, { params: { load_id: loadId } });
    return response;
  },
  async getPayMelioLink(loadId) {
    const response = await api.post(`${melio}`, {}, { params: { load_id: loadId } });
    return response.data;
  },
  async getAllBillingsToPay(params) {
    const response = await api.get(`${melio}/billings`, { params });
    const { data } = response;
    const transformedData = data.map((item) => ({ ...item, is_checked: false }));
    return transformedData;
  },
  async getPaymentHistory(params) {
    const response = await api.get(`${melio}/history`, { params });
    return response.data;
  },
  async getFile(params) {
    const response = await api.get(`${melio}/files`, { params });
    return response.data;
  },
  async goToPayment(billingIds) {
    const response = await api.post(`${melio}/pay`, { billing_ids: billingIds });
    return response.data;
  },
};
