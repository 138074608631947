import api from "../clients/axios";

const resource = "address";

export default {
  async verifyStopByAccount(id) {
    const response = await api.post(`${resource}/verify-stop/account/${id}`);
    return response.data;
  },
};
