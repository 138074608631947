import { errorMessage, generalMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const claims = Repository.service("claims");

export default {
  namespaced: true,
  state: {
    infoclaimOrDispute: [],
    selectedClaimOrDispute: null,
    reviewClaimOrDispute: null,
    claimButton: null,
    disputeButton: null,
  },
  getters: {
    getInfoClaimOrDispute(state) {
      return state.infoclaimOrDispute;
    },
    getSelectedClaimOrDispute(state) {
      return state.selectedClaimOrDispute;
    },
    getReviewClaimOrDispute(state) {
      return state.reviewClaimOrDispute;
    },
    getClaimButton(state) {
      return state.claimButton;
    },
    getDisputeButton(state) {
      return state.disputeButton;
    },
  },
  mutations: {
    setInfoClaimOrDispute(state, payload) {
      state.infoclaimOrDispute = payload;
    },
    setSelectedClaimOrDispute(state, payload) {
      state.selectedClaimOrDispute = payload;
    },
    setReviewClaimOrDispute(state, payload) {
      state.reviewClaimOrDispute = payload;
    },
    setClaimButton(state, payload) {
      state.claimButton = payload;
    },
    setDisputeButton(state, payload) {
      state.claimButton = payload;
    },
  },
  actions: {
    async getClaimsById({ rootGetters }, id) {
      try {
        const response = await claims.getClaimsById(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createClaims({ rootGetters }, body) {
      try {
        const response = await claims.createClaims(body);
        generalMessage(response[rootGetters.getLanguage]);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateClaims({ rootGetters }, body) {
      try {
        const response = await claims.updateClaims(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
