import api from "../clients/axios";

const resource = "auth/access-token";

async function validateMagayaCredentials(params) {
  const response = await api.get(`auth/credentials`, { params });
  return response.data;
}

async function signIn(body) {
  const response = await api.post(`${resource}`, body);
  return response.data;
}

async function signInMagaya(params) {
  const response = await api.post(`auth/magaya/access-token`, null, { params });
  return response.data;
}

async function refreshToken(params) {
  const response = await api.post(`auth/refresh`, null, { params });
  return response.data;
}

async function loginWithAccountId(accountId) {
  const response = await api.post(`${resource}/${accountId}`);
  return response.data;
}

export default {
  signIn,
  signInMagaya,
  refreshToken,
  loginWithAccountId,
  validateMagayaCredentials,
};
