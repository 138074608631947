import api from "../clients/axios";

const resource = "salesforce-accounts"; // Check the route in the backend docs

export default {
  async getSalesforceAccounts(data) {
    const response = await api.get(`${resource}`, { params: data.params });
    return response.data;
  },
  async createSalesforceAccounts(body) {
    const response = await api.post(`${resource}`, body, {
      params: {
        create: body.createLocationInBd,
      },
    });
    return response.data;
  },
  async deleteSalesforceAccount(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
  async updateSalesforceAccount(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, payload.body);
    return response.data;
  },
};
