import api from "../clients/axios";

const resource = "carousel";

export default {
  async getAllCarouselInfo() {
    const response = await api.get(`${resource}`, { params: { skip: 0, limit: 100 } });
    return response.data;
  },
  async uploadVideo(payload) {
    const response = await api.post(`${resource}/video?url=${payload}`);
    return response;
  },
  async uploadImage(payload) {
    const response = await api.post(`${resource}/upload/image?redirect_url=${payload.redirectUrlToSend}`, payload.formData);
    return response;
  },
  async updateCarouselItems(payload) {
    const response = await api.patch(`${resource}/${payload.id}`, payload.body);
    return response;
  },
  async updateCarouselOrder(payload) {
    const response = await api.patch(`${resource}/multiple`, payload);
    return response;
  },
  async deleteItemCarousel(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  },
};
