import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("dateFormat", (value) => {
  const formattedDate = dayjs(value).format("MM-DD-YYYY");
  if (formattedDate.toLowerCase() === 'invalid date') {
    return value;
  }
  return formattedDate;
});
