import api from "../clients/axios";

const resource = "quote-file";

export default {
  async getQuoteFiles(payload) {
    const params = { ...payload };
    delete params.exclude_carriers;
    const response = await api.get(
      `${resource}?${payload.exclude_carriers.map((item) => `exclude_carriers=${item}`).join("&")}`,
      {
        params,
        responseType: "blob",
      }
    );
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    }
    return response;
  },
  async getQuoteFileExcel(payload) {
    const params = { ...payload };
    delete params.exclude_carriers;
    const response = await api.get(
      `${resource}/excel?${payload.exclude_carriers
        .map((item) => `exclude_carriers=${item}`)
        .join("&")}`,
      {
        params,
        responseType: "blob",
      }
    );
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    }
    return response.data;
  },
  async sendQuoteFile(payload) {
    const params = { ...payload.params };
    delete params.exclude_carriers;
    const response = await api.post(
      `${resource}?${payload.params.exclude_carriers
        .map((item) => `exclude_carriers=${item}`)
        .join("&")}`,
      payload.body,
      {
        params,
      }
    );
    return response.data;
  },
  async sendQuoteFileExcel(payload) {
    const params = { ...payload.params };
    delete params.exclude_carriers;
    const response = await api.post(
      `${resource}/excel?${payload.params.exclude_carriers
        .map((item) => `exclude_carriers=${item}`)
        .join("&")}`,
      payload.body,
      {
        params,
      }
    );
    return response.data;
  },
};
