const permissions = {
  adminRole: [
    "post:user",
    "patch:user",
    "get:users",
    "get:user",
    "delete:user",
    "post:role",
    "get:roles",
    "get:role",
    "patch:role",
    "delete:role",
    "get:role-permission",
    "post:role-permission",
    "delete:role-permission",
    "get:account-settings",
    "post:salesforce-account",
    "get:salesforce-account",
    "get:salesforce-accounts",
    "patch:salesforce-account",
    "delete:salesforce-account",
    "get:accessorials",
    "post:accessorial",
    "delete:accessorial",
    "get:accounts",
    "get:account",
    "patch:account",
    "get:line-item-picklists",
    "post:line-item",
    "patch:line-item",
    "delete:line-item",
    "get:loads",
    "get:load",
    "get:load-picklists",
    "post:load",
    "patch:load",
    "get:load-accessorials",
    "get:load-accessorial",
    "post:load-accessorial",
    "patch:load-accessorial",
    "delete:load-accessorial",
    "post:salesforce-contact",
    "get:salesforce-contact",
    "get:salesforce-contacts",
    "patch:salesforce-contact",
    "delete:salesforce-contact",
    "post:stop",
    "patch:stop",
    "delete:stop",
    "get:history",
    "get:docs-generate",
    "get:claims",
    "post:claim",
    "patch:claim",
    "post:quote",
    "get:carriers",
    "get:permissions",
    "post:permissions",
    "delete:permissions",
    "get:standardize-nmfc",
    "get:me",
    "get:role-permissions",
    "post:role-permission",
    "delete:role-permission",
    "get:pcmiller",
    "post:tender",
    "get:truck-type",
    "delete:truck-type",
    "post:truck-type",
    "patch:truck-type",
    "get:share-files",
    "get:carrier-alert",
    "post:carrier-alert",
    "patch:carrier-alert",
    "delete:carrier-alert",
    "get:quote-file",
    "get:shipping-label",
    "post:quote-file",
    "get:home",
    "patch:home",
    "delete:account"
  ],
  accounAdminWithoutBranded: [
    "post:user",
    "patch:user",
    "get:users",
    "get:user",
    "delete:user",
    "post:role",
    "get:roles",
    "get:role",
    "patch:role",
    "delete:role",
    "get:role-permission",
    "post:role-permission",
    "delete:role-permission",
    "get:account-settings",
    "post:salesforce-account",
    "get:salesforce-account",
    "get:salesforce-accounts",
    "patch:salesforce-account",
    "delete:salesforce-account",
    "get:accessorials",
    "post:accessorial",
    "delete:accessorial",
    "get:accounts",
    "get:account",
    "patch:account",
    "get:line-item-picklists",
    "post:line-item",
    "patch:line-item",
    "delete:line-item",
    "get:loads",
    "get:load",
    "get:load-picklists",
    "post:load",
    "patch:load",
    "get:load-accessorials",
    "get:load-accessorial",
    "post:load-accessorial",
    "patch:load-accessorial",
    "delete:load-accessorial",
    "post:salesforce-contact",
    "get:salesforce-contact",
    "get:salesforce-contacts",
    "patch:salesforce-contact",
    "delete:salesforce-contact",
    "post:stop",
    "patch:stop",
    "delete:stop",
    "get:history",
    "get:docs-generate",
    "get:claims",
    "post:claim",
    "patch:claim",
    "post:quote",
    "get:carriers",
    "get:permissions",
    "post:permissions",
    "get:standardize-nmfc",
    "get:me",
    "get:role-permissions",
    "post:role-permission",
    "delete:role-permission",
    "get:truck-type",
    "get:pcmiller",
    "post:tender",
    "delete:truck-type",
    "post:truck-type",
    "patch:truck-type",
    "delete:permissions",
    "get:share-files",
    "get:carrier-alert",
    "post:carrier-alert",
    "patch:carrier-alert",
    "delete:carrier-alert",
    "get:quote-file",
    "get:shipping-label",
    "post:quote-file",
    "get:home",
    "patch:home",
    "delete:account"
  ],
  accountAdminWithoutDispatch: [
    "post:user",
    "patch:user",
    "get:users",
    "get:user",
    "delete:user",
    "post:role",
    "get:roles",
    "get:role",
    "patch:role",
    "delete:role",
    "get:role-permission",
    "post:role-permission",
    "delete:role-permission",
    "get:account-settings",
    "patch:account-settings",
    "post:salesforce-account",
    "get:salesforce-account",
    "get:salesforce-accounts",
    "patch:salesforce-account",
    "delete:salesforce-account",
    "get:accessorials",
    "post:accessorial",
    "delete:accessorial",
    "get:accounts",
    "get:account",
    "patch:account",
    "get:line-item-picklists",
    "post:line-item",
    "patch:line-item",
    "delete:line-item",
    "get:loads",
    "get:load",
    "get:load-picklists",
    "post:load",
    "patch:load",
    "get:load-accessorials",
    "get:load-accessorial",
    "post:load-accessorial",
    "patch:load-accessorial",
    "delete:load-accessorial",
    "post:salesforce-contact",
    "get:salesforce-contact",
    "get:salesforce-contacts",
    "patch:salesforce-contact",
    "delete:salesforce-contact",
    "post:stop",
    "patch:stop",
    "delete:stop",
    "get:history",
    "get:docs-generate",
    "get:claims",
    "post:claim",
    "patch:claim",
    "post:quote",
    "get:carriers",
    "get:permissions",
    "post:permissions",
    "get:standardize-nmfc",
    "get:me",
    "get:role-permissions",
    "post:role-permission",
    "delete:role-permission",
    "get:truck-type",
    "get:pcmiller",
    "delete:truck-type",
    "post:truck-type",
    "patch:truck-type",
    "delete:permissions",
    "get:share-files",
    "get:carrier-alert",
    "post:carrier-alert",
    "patch:carrier-alert",
    "delete:carrier-alert",
    "get:quote-file",
    "get:shipping-label",
    "post:quote-file",
    "get:home",
    "patch:home",
    "delete:account"
  ],
  accountAdminWithoutBrandedAndDispatch: [
    "post:user",
    "patch:user",
    "get:users",
    "get:user",
    "delete:user",
    "post:role",
    "get:roles",
    "get:role",
    "patch:role",
    "delete:role",
    "get:role-permission",
    "post:role-permission",
    "delete:role-permission",
    "get:account-settings",
    "post:salesforce-account",
    "get:salesforce-account",
    "get:salesforce-accounts",
    "patch:salesforce-account",
    "delete:salesforce-account",
    "get:accessorials",
    "post:accessorial",
    "delete:accessorial",
    "get:accounts",
    "get:account",
    "patch:account",
    "get:line-item-picklists",
    "post:line-item",
    "patch:line-item",
    "delete:line-item",
    "get:loads",
    "get:load",
    "get:load-picklists",
    "post:load",
    "patch:load",
    "get:load-accessorials",
    "get:load-accessorial",
    "post:load-accessorial",
    "patch:load-accessorial",
    "delete:load-accessorial",
    "post:salesforce-contact",
    "get:salesforce-contact",
    "get:salesforce-contacts",
    "patch:salesforce-contact",
    "delete:salesforce-contact",
    "post:stop",
    "patch:stop",
    "delete:stop",
    "get:history",
    "get:docs-generate",
    "get:claims",
    "post:claim",
    "patch:claim",
    "post:quote",
    "get:carriers",
    "get:permissions",
    "post:permissions",
    "get:standardize-nmfc",
    "get:me",
    "get:role-permissions",
    "post:role-permission",
    "delete:role-permission",
    "get:truck-type",
    "get:pcmiller",
    "delete:truck-type",
    "post:truck-type",
    "patch:truck-type",
    "delete:permissions",
    "get:share-files",
    "get:carrier-alert",
    "post:carrier-alert",
    "patch:carrier-alert",
    "delete:carrier-alert",
    "get:quote-file",
    "get:shipping-label",
    "post:quote-file",
    "get:home",
    "patch:home",
    "delete:account"
  ],
  accountAdminWithBrandedAndDispatch: [
    "post:user",
    "patch:user",
    "get:users",
    "get:user",
    "delete:user",
    "post:role",
    "get:roles",
    "get:role",
    "patch:role",
    "delete:role",
    "get:role-permission",
    "post:role-permission",
    "delete:role-permission",
    "get:account-settings",
    "patch:account-settings",
    "post:salesforce-account",
    "get:salesforce-account",
    "get:salesforce-accounts",
    "patch:salesforce-account",
    "delete:salesforce-account",
    "get:accessorials",
    "post:accessorial",
    "delete:accessorial",
    "get:accounts",
    "get:account",
    "patch:account",
    "get:line-item-picklists",
    "post:line-item",
    "patch:line-item",
    "delete:line-item",
    "get:loads",
    "get:load",
    "get:load-picklists",
    "post:load",
    "patch:load",
    "get:load-accessorials",
    "get:load-accessorial",
    "post:load-accessorial",
    "patch:load-accessorial",
    "delete:load-accessorial",
    "post:salesforce-contact",
    "get:salesforce-contact",
    "get:salesforce-contacts",
    "patch:salesforce-contact",
    "delete:salesforce-contact",
    "post:stop",
    "patch:stop",
    "delete:stop",
    "get:history",
    "get:docs-generate",
    "get:claims",
    "post:claim",
    "patch:claim",
    "post:quote",
    "get:carriers",
    "get:permissions",
    "post:permissions",
    "delete:permissions",
    "get:standardize-nmfc",
    "get:me",
    "get:role-permissions",
    "post:role-permission",
    "delete:role-permission",
    "get:pcmiller",
    "post:tender",
    "get:truck-type",
    "delete:truck-type",
    "post:truck-type",
    "patch:truck-type",
    "get:share-files",
    "get:carrier-alert",
    "post:carrier-alert",
    "patch:carrier-alert",
    "delete:carrier-alert",
    "get:quote-file",
    "get:shipping-label",
    "post:quote-file",
    "get:home",
    "patch:home",
    "delete:account"
  ]
};

export default permissions;
