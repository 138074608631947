// import ls from "@/plugins/secureLs";
import { errorMessage } from "../../utils/ErrorMessages";

function eraseLocalStorageInfo() {
  localStorage.removeItem("portal-front-token");
  localStorage.removeItem("portal-front-refresh-token");
  localStorage.removeItem("portal-");
  localStorage.removeItem("user-settings");
  localStorage.removeItem("my-load-table");
}

const LoginRoute = [
  {
    path: "/login",
    name: "Login",
    component: () => {
      if (process.env.VUE_APP_BRANDED_APP === "true") {
        return import("../../views/LoginStandard/Index.vue");
      }
      return import("../../views/Login/Index.vue");
    },
    beforeEnter: (to, from, next) => {
      eraseLocalStorageInfo();
      next();
    }
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    meta: {
      title: "Create Account | Portal"
    },
    component: () => import("../../views/CreateAccount/Index.vue"),
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_BRANDED_APP === "false") {
        next();
      } else {
        next("/404");
        errorMessage("Try to access to Portal from Magaya extensions");
      }
    },
  },
  {
    path: "/create-account-magaya",
    name: "CreateAccountMagaya",
    component: () => import("../../views/CreateAccountMagaya/Index.vue"),
    beforeEnter: (to, from, next) => {
      if (to.query.magaya_company_guid
        && to.query.magaya_network_id
        && to.query.magaya_user_guid
        && process.env.VUE_APP_BRANDED_APP === "false") {
        next();
      } else {
        next("/404");
        errorMessage("Try to access to Portal from Magaya extensions");
      }
    },
  },
  {
    path: "/recover-password",
    name: "RecoverPassword",
    component: () => {
      if (process.env.VUE_APP_BRANDED_APP === "true") {
        return import("../../views/RecoverPasswordStandard/Index.vue");
      }
      return import("../../views/RecoverPassword/Index.vue");
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => {
      if (process.env.VUE_APP_BRANDED_APP === "true") {
        return import("../../views/ForgotPasswordStandard/Index.vue");
      }
      return import("../../views/ForgotPassword/Index.vue");
    },
  },
];
export default LoginRoute;
